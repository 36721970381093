var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-col", { attrs: { cols: _vm.isMobile ? 12 : _vm.cff.size } }, [
    _vm.cff.type == 0
      ? _c(
          "div",
          {},
          [
            _c("v-text-field", {
              ref: "cff",
              attrs: {
                value: _vm.val,
                label: _vm.cff.name,
                rules: _vm.cff.required
                  ? [() => !!_vm.val || "This field is required"]
                  : [],
                required: _vm.cff.required,
              },
              on: { input: _vm.handleCFFInput },
              model: {
                value: _vm.val,
                callback: function ($$v) {
                  _vm.val = $$v
                },
                expression: "val",
              },
            }),
            _vm.cff.required
              ? _c("span", [
                  _c("small", { staticClass: "red--text" }, [
                    _vm._v("*Required"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.cff.type == 1
      ? _c(
          "div",
          {},
          [
            _c("v-text-field", {
              ref: "cff",
              attrs: {
                value: _vm.val,
                label: _vm.cff.name,
                type: "number",
                min: "0",
                rules: _vm.cff.required
                  ? [
                      () =>
                        (_vm.val && _vm.val >= 0) || "This field is required",
                    ]
                  : [],
                required: _vm.cff.required,
              },
              on: { input: _vm.handleCFFInput },
              model: {
                value: _vm.val,
                callback: function ($$v) {
                  _vm.val = $$v
                },
                expression: "val",
              },
            }),
            _vm.cff.required
              ? _c("span", [
                  _c("small", { staticClass: "red--text" }, [
                    _vm._v("*Required"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.cff.type == 2
      ? _c(
          "div",
          [
            _c("date-picker", {
              ref: "cff",
              attrs: {
                value: _vm.val,
                label: _vm.cff.name,
                readonly: _vm.readonly,
                rules: _vm.cff.required
                  ? [() => !!_vm.val || "This field is required"]
                  : [],
                required: _vm.cff.required,
              },
              on: { input: _vm.handleCFFInput },
            }),
            _vm.cff.required
              ? _c("span", [
                  _c("small", { staticClass: "red--text" }, [
                    _vm._v("*Required"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.cff.type == 3
      ? _c(
          "div",
          [
            _c("v-checkbox", {
              ref: "cff",
              attrs: { value: _vm.val, label: _vm.cff.name },
              on: { change: _vm.handleCFFInput },
              model: {
                value: _vm.val,
                callback: function ($$v) {
                  _vm.val = $$v
                },
                expression: "val",
              },
            }),
            _vm.cff.required
              ? _c("span", [
                  _c("small", { staticClass: "red--text" }, [
                    _vm._v("*Required"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.cff.type == 4
      ? _c(
          "div",
          [
            _c("v-select", {
              ref: "cff",
              attrs: {
                value: _vm.val,
                items: _vm.cff.items,
                label: _vm.cff.name,
                rules: _vm.cff.required
                  ? [() => !!_vm.val || "This field is required"]
                  : [],
                required: _vm.cff.required,
                outlined: "",
              },
              on: { change: _vm.handleCFFInput },
              model: {
                value: _vm.val,
                callback: function ($$v) {
                  _vm.val = $$v
                },
                expression: "val",
              },
            }),
            _vm.cff.required
              ? _c("span", [
                  _c("small", { staticClass: "red--text" }, [
                    _vm._v("*Required"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }