var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-form", { staticClass: "full-width" }, [
        _c(
          "div",
          [
            _vm.showFundingSources
              ? [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: _vm.addFSDisabled,
                              },
                              on: { click: _vm.addFundingSource },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-plus")]),
                              _vm._v(
                                " " + _vm._s(_vm.fundingSourceLabel) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.fsRequired
                        ? _c("v-subheader", [
                            _vm._v(
                              "A Funding Source is required for the selected Trip Type"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        _vm._l(
                          _vm.tripRequest.fundingSources,
                          function (item, index) {
                            return _c(
                              "v-card",
                              { key: item.id ?? index, staticClass: "mb-4" },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c("FundingSourceWithBudgetCode", {
                                          attrs: {
                                            fsLabel: `${
                                              _vm.fundingSourceLabel
                                            } #${index + 1}`,
                                            disabled:
                                              _vm.tripRequest.fundingSources[
                                                index
                                              ].delete,
                                            readonly: _vm.cannotModifyFields,
                                            hint: _vm.fundingSourcesById[
                                              _vm.tripRequest.fundingSources[
                                                index
                                              ].fundingSourceId
                                            ]
                                              ? _vm.fundingSourcesById[
                                                  _vm.tripRequest
                                                    .fundingSources[index]
                                                    .fundingSourceId
                                                ].description
                                              : "",
                                            fundingSourceIndex: index,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.setTripFunding({
                                                val: $event,
                                                index,
                                              })
                                            },
                                          },
                                        }),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "4" },
                                              },
                                              [
                                                _vm.showApproverInfo(
                                                  _vm.fundingSourcesById[
                                                    _vm.tripRequest
                                                      .fundingSources[index]
                                                      .fundingSourceId
                                                  ]
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Funding Approver: "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.showAllotmentBalance(
                                                  _vm.fundingSourcesById[
                                                    _vm.tripRequest
                                                      .fundingSources[index]
                                                      .fundingSourceId
                                                  ]
                                                )
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-subtitle-2 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Est Trip Cost:"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-subtitle-2 font-weight-bold",
                                                        },
                                                        [_vm._v("Est Balance:")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "8" },
                                              },
                                              [
                                                _vm.showApproverInfo(
                                                  _vm.fundingSourcesById[
                                                    _vm.tripRequest
                                                      .fundingSources[index]
                                                      .fundingSourceId
                                                  ]
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "text-subtitle-2 font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .fundingSourcesById[
                                                                _vm.tripRequest
                                                                  .fundingSources[
                                                                  index
                                                                ]
                                                                  .fundingSourceId
                                                              ].approverUsername
                                                            ) +
                                                            " (" +
                                                            _vm._s(
                                                              _vm
                                                                .fundingSourcesById[
                                                                _vm.tripRequest
                                                                  .fundingSources[
                                                                  index
                                                                ]
                                                                  .fundingSourceId
                                                              ].approverEmail
                                                            ) +
                                                            ") "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.showAllotmentBalance(
                                                  _vm.fundingSourcesById[
                                                    _vm.tripRequest
                                                      .fundingSources[index]
                                                      .fundingSourceId
                                                  ]
                                                )
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-subtitle-2 font-weight-normal",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.toMoney(
                                                                _vm.estTripCost
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-subtitle-2 font-weight-normal",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.toMoney(
                                                                  _vm.estimateBalance(
                                                                    _vm
                                                                      .fundingSourcesById[
                                                                      _vm
                                                                        .tripRequest
                                                                        .fundingSources[
                                                                        index
                                                                      ]
                                                                        .fundingSourceId
                                                                    ] &&
                                                                      _vm
                                                                        .fundingSourcesById[
                                                                        _vm
                                                                          .tripRequest
                                                                          .fundingSources[
                                                                          index
                                                                        ]
                                                                          .fundingSourceId
                                                                      ].budget
                                                                      ? _vm
                                                                          .fundingSourcesById[
                                                                          _vm
                                                                            .tripRequest
                                                                            .fundingSources[
                                                                            index
                                                                          ]
                                                                            .fundingSourceId
                                                                        ].budget
                                                                          .allotment
                                                                      : 0
                                                                  )
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _vm.showAllotmentBalance(
                                              _vm.fundingSourcesById[
                                                _vm.tripRequest.fundingSources[
                                                  index
                                                ].fundingSourceId
                                              ]
                                            ) &&
                                            _vm.estimateBalance(
                                              _vm.fundingSourcesById[
                                                _vm.tripRequest.fundingSources[
                                                  index
                                                ].fundingSourceId
                                              ] &&
                                                _vm.fundingSourcesById[
                                                  _vm.tripRequest
                                                    .fundingSources[index]
                                                    .fundingSourceId
                                                ].budget
                                                ? _vm.fundingSourcesById[
                                                    _vm.tripRequest
                                                      .fundingSources[index]
                                                      .fundingSourceId
                                                  ].budget.allotment
                                                : 0
                                            ) < 0
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      md: "12",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-alert",
                                                      {
                                                        attrs: {
                                                          outlined: "",
                                                          type: "error",
                                                          text: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .tripRequestConfig
                                                              .messages
                                                              .allotmentDepleted
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.cannotModifyFields ||
                                            _vm.fsRequired,
                                          color: "error",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteFundingSource(
                                              item,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.delete
                                                  ? "mdi-refresh"
                                                  : "mdi-delete"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.delete ? "Undo" : "Delete"
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm.showThirdPartyOptions
              ? [
                  _c(
                    "v-row",
                    { staticClass: "mt-4" },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                        _vm._v(
                          _vm._s(_vm.tripRequestConfig.labels.thirdPartyPayment)
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c("span", [_vm._v("No")]),
                            _c("v-switch", {
                              staticClass: "ml-4",
                              attrs: {
                                "true-value": 1,
                                "false-value": 0,
                                inset: "",
                                readonly: _vm.cannotModifyFields,
                              },
                              on: { change: _vm.handleThirdParty },
                              model: {
                                value: _vm.tripRequest.payableToThirdParty,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tripRequest,
                                    "payableToThirdParty",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "tripRequest.payableToThirdParty",
                              },
                            }),
                            _c("span", [_vm._v("Yes")]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.tripRequest.payableToThirdParty == 1
                    ? [
                        _c(
                          "v-row",
                          { staticClass: "ml-1", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Amount",
                                    type: "number",
                                    min: "0",
                                    "prepend-icon": "mdi-currency-usd",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  model: {
                                    value: _vm.tripRequest.thirdPartyAmount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "thirdPartyAmount",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "tripRequest.thirdPartyAmount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                attrs: { cols: "12", md: "3" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("div", [_vm._v("Payment Option")]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1594390894
                                ),
                              },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { readonly: _vm.cannotModifyFields },
                                    model: {
                                      value: _vm.tripRequest.thirdPartyOption,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tripRequest,
                                          "thirdPartyOption",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tripRequest.thirdPartyOption",
                                    },
                                  },
                                  _vm._l(
                                    _vm.filteredPayments,
                                    function (option) {
                                      return _c("v-radio", {
                                        key: option.id,
                                        attrs: {
                                          label: option.name,
                                          value: option.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c("v-subheader", [_vm._v("Payment Due To")]),
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "9" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Name",
                                            outlined: "",
                                            readonly: _vm.cannotModifyFields,
                                          },
                                          model: {
                                            value: _vm.tripRequest.thirdPName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tripRequest,
                                                "thirdPName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tripRequest.thirdPName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "9" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Address",
                                            outlined: "",
                                            readonly: _vm.cannotModifyFields,
                                          },
                                          model: {
                                            value:
                                              _vm.tripRequest.thirdPAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tripRequest,
                                                "thirdPAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tripRequest.thirdPAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "6" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "City",
                                            outlined: "",
                                            readonly: _vm.cannotModifyFields,
                                          },
                                          model: {
                                            value: _vm.tripRequest.thirdPCity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tripRequest,
                                                "thirdPCity",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tripRequest.thirdPCity",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "State",
                                            outlined: "",
                                            readonly: _vm.cannotModifyFields,
                                          },
                                          model: {
                                            value: _vm.tripRequest.thirdPState,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tripRequest,
                                                "thirdPState",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tripRequest.thirdPState",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Zip",
                                            outlined: "",
                                            readonly: _vm.cannotModifyFields,
                                          },
                                          model: {
                                            value: _vm.tripRequest.thirdPZip,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tripRequest,
                                                "thirdPZip",
                                                $$v
                                              )
                                            },
                                            expression: "tripRequest.thirdPZip",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.tripRequest.thirdPartyOption == 2
                          ? _c(
                              "v-row",
                              { staticClass: "ml-1", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "P-Card Account Number",
                                        outlined: "",
                                        readonly: _vm.cannotModifyFields,
                                      },
                                      model: {
                                        value: _vm.tripRequest.pcardAccount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tripRequest,
                                            "pcardAccount",
                                            $$v
                                          )
                                        },
                                        expression: "tripRequest.pcardAccount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          { staticClass: "ml-1", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label:
                                      "Purchase Order / Requisition Number",
                                    outlined: "",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  model: {
                                    value: _vm.tripRequest.thirdPartyPO,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "thirdPartyPO",
                                        $$v
                                      )
                                    },
                                    expression: "tripRequest.thirdPartyPO",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "ml-1", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    label: "Comments Concerning Payment",
                                    dense: "",
                                    outlined: "",
                                    rows: "3",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  model: {
                                    value: _vm.tripRequest.thirdPartyComments,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "thirdPartyComments",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tripRequest.thirdPartyComments",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.customFormFields
              ? _c(
                  "v-row",
                  _vm._l(_vm.customFormFields, function (cff, i) {
                    return _c("custom-form-field", {
                      key: i,
                      ref: cff.id,
                      refInFor: true,
                      attrs: {
                        cff: cff,
                        value: _vm.tripRequest.customFormFields[cff.id],
                        readonly: _vm.cannotModifyFields,
                      },
                      on: {
                        handleCFFInput: function ($event) {
                          return _vm.$emit("handleCFFInput", {
                            cffId: cff.id,
                            value: $event,
                          })
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }