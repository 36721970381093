var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              !_vm.showForm
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.openForm },
                    },
                    [_vm._v("Send Email")]
                  )
                : _c(
                    "v-form",
                    { ref: "form", on: { submit: _vm.send } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-combobox", {
                                            attrs: {
                                              items: _vm.userEmails,
                                              "item-text": "email",
                                              label: "Send to",
                                              multiple: "",
                                              outlined: "",
                                              chips: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.$v.form.sendTo.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.form.sendTo,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.form.sendTo.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              outlined: "",
                                              label: "Subject",
                                              value: _vm.form.subject,
                                              required: "",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.$v.form.subject.$touch()
                                              },
                                            },
                                            model: {
                                              value: _vm.$v.form.subject.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.form.subject,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.form.subject.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              outlined: "",
                                              rows: "3",
                                              "auto-grow": "",
                                              label: "Message",
                                              value: _vm.form.content,
                                              required: "",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.$v.form.content.$touch()
                                              },
                                            },
                                            model: {
                                              value: _vm.$v.form.content.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.form.content,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.form.content.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "right",
                                              on: {
                                                click: function ($event) {
                                                  _vm.showForm = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "right",
                                              attrs: {
                                                color: "green",
                                                dark: "",
                                              },
                                              on: { click: _vm.send },
                                            },
                                            [_vm._v(" Send ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  "fixed-header": "",
                  headers: _vm.headers,
                  items: _vm.emails,
                  "item-key": "id",
                  "sort-by": "time",
                  "disable-sort": "",
                  "no-data-text": "No emails yet!",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.time`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  new Date(item.timestamp * 1000),
                                  "PP p"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.content`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", "max-width": "70%" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "p",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "truncate" },
                                              "p",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(_vm._s(item.content))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.content))])]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }