var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { staticClass: "full-width" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _vm.fileRequired
                    ? _c("span", { staticClass: "red--text" }, [
                        _c("small", [_vm._v("*Required")]),
                      ])
                    : _vm._e(),
                  _c("upload-file", {
                    ref: "uploadFile",
                    attrs: {
                      hint: _vm.fileHint,
                      recordId: _vm.tripRequest.id,
                      required: _vm.fileRequired,
                      persistentHint: "",
                      recordType: "triprequest",
                      showSize: "",
                      readonly: _vm.readonly,
                    },
                    on: { onUpload: _vm.onUpload },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("attachments", {
                    ref: "attachments",
                    attrs: {
                      batchId: _vm.tripRequest.batchId,
                      recordId: _vm.tripRequest.id,
                      recordType: "triprequest",
                    },
                    on: {
                      onFileDelete: function ($event) {
                        return _vm.$emit("refresh")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.customFormFields
            ? _c(
                "v-row",
                _vm._l(_vm.customFormFields, function (cff, i) {
                  return _c("custom-form-field", {
                    key: i,
                    attrs: {
                      cff: cff,
                      value: _vm.tripRequest.customFormFields[cff.id],
                      readonly: _vm.readonly,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.$emit("handleCFFInput", {
                          cffId: cff.id,
                          value: $event,
                        })
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }