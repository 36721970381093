var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", "no-click-animation": "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("v-card", [
        _c(
          "form",
          [
            _c("v-card-title", [_vm._v("Add Trip Stop")]),
            _c("v-divider"),
            _c(
              "v-card-text",
              { staticClass: "py-5" },
              [
                _c(
                  "v-form",
                  { ref: "form" },
                  [
                    _c("destination-autocomplete", {
                      ref: "id",
                      attrs: {
                        destinationFilters: _vm.tripRequestConfig.display
                          .newDestination
                          ? {}
                          : { prospects: 0 },
                        loading: _vm.loadingDestinations,
                        "search-input": _vm.searchText,
                        hint: "Use this field to prefill the form below, or complete the form manually",
                        outlined: "",
                        "persistent-hint": "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.searchText = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.searchText = $event
                        },
                        destinationChanged: _vm.handleNewDestination,
                        internalLoading: (val) =>
                          (_vm.loadingDestinations = val),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function ({ on, attrs, item }) {
                            return [
                              _c(
                                "v-list-item",
                                _vm._g(
                                  _vm._b({}, "v-list-item", attrs, false),
                                  on
                                ),
                                [
                                  item.place_id
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "orange", left: "" },
                                        },
                                        [_vm._v("mdi-map-marker-plus")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { color: "green", left: "" } },
                                        [_vm._v("mdi-map-marker")]
                                      ),
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.text)),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.destinationId,
                        callback: function ($$v) {
                          _vm.destinationId = $$v
                        },
                        expression: "destinationId",
                      },
                    }),
                    _c("v-text-field", {
                      ref: "name",
                      attrs: {
                        rules: [
                          () => !!_vm.$v.form.name.$model || "Required field",
                        ],
                        label: "Name",
                        required: "",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.$v.form.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.$v.form.name.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.form.name,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.form.name.$model",
                      },
                    }),
                    _c(
                      "v-row",
                      { staticClass: "pt-2" },
                      [
                        _c("address-form", {
                          ref: "aform",
                          attrs: {
                            multiple: false,
                            showLatLong: true,
                            required: "",
                          },
                          model: {
                            value: _vm.$v.form.address.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.address, "$model", $$v)
                            },
                            expression: "$v.form.address.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-card-actions",
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", text: "" },
                    on: { click: _vm.close },
                  },
                  [_vm._v("Close")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "success",
                      disabled: _vm.$v.$invalid,
                      loading: _vm.saving,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.save()
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }