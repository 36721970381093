var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6" } },
    [
      _c("div", { staticClass: "text-h5 ml-18 d-inline-block" }, [
        _vm._v("Build Trip Itinerary"),
      ]),
      _c("p", { staticClass: "ma-0 pa-0" }, [
        _c(
          "small",
          {
            staticClass:
              "blue-grey--text text--accent-3 font-weight-bold ml-18",
          },
          [_vm._v("*You can click on a stop and drag to change order.")]
        ),
      ]),
      _vm.tripRequest.distance > 0
        ? _c("div", { staticClass: "text-subtitle-1 ml-18 d-inline-block" }, [
            _vm._v(
              " Total Mileage: " + _vm._s(_vm.tripRequest.distance) + " miles "
            ),
          ])
        : _vm._e(),
      _vm.tripRequest.time > 0
        ? _c("div", { staticClass: "text-subtitle-1 ml-18 d-inline-block" }, [
            _vm._v("Total Time: " + _vm._s(_vm.time)),
          ])
        : _vm._e(),
      !_vm.tripRequest.destinationId
        ? _c(
            "v-banner",
            {
              staticClass: "ma-2",
              attrs: {
                rounded: "",
                color: "red lighten-4",
                icon: "mdi-alert-box",
              },
            },
            [
              _vm._v(
                " You must have a main destination selected before editing your itinerary "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-list",
        [
          _c(
            "v-list-item-group",
            {
              attrs: { color: "primary" },
              model: {
                value: _vm.currentIndex,
                callback: function ($$v) {
                  _vm.currentIndex = $$v
                },
                expression: "currentIndex",
              },
            },
            [
              _c(
                "draggable",
                {
                  attrs: { disabled: _vm.fieldsDisabled },
                  on: { sort: _vm.handleSort },
                  model: {
                    value: _vm.tripRequest.stops,
                    callback: function ($$v) {
                      _vm.$set(_vm.tripRequest, "stops", $$v)
                    },
                    expression: "tripRequest.stops",
                  },
                },
                _vm._l(_vm.existingStops, function (stop) {
                  return _c(
                    "v-list-item",
                    { key: stop.id },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            { attrs: { disabled: _vm.fieldsDisabled } },
                            [_vm._v("mdi-drag-horizontal-variant")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mr-2" },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.getRouteIcon(stop))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(stop.name))])],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                disabled: _vm.fieldsDisabled,
                                small: "",
                                color: "error",
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delStop(stop)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-delete")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.tripRequest.destinationId
            ? _c(
                "v-col",
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("Outbound Trip"),
                  ]),
                  _vm.loading
                    ? [
                        _c("v-progress-circular", {
                          attrs: {
                            size: 30,
                            color: "primary",
                            indeterminate: "",
                          },
                        }),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v("Calculating route..."),
                        ]),
                      ]
                    : _vm.successOutbound
                    ? [
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _c("strong", [_vm._v("Miles to Destination:")]),
                          _vm._v(" " + _vm._s(_vm.oMileage)),
                        ]),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _c("strong", [_vm._v("Drive Time to Destination:")]),
                          _vm._v(" " + _vm._s(_vm.oTime)),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", width: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.showDirections(0)
                              },
                            },
                          },
                          [_vm._v("Outbound Directions")]
                        ),
                      ]
                    : _vm._l(_vm.outboundErrors, function (message, index) {
                        return _c(
                          "v-banner",
                          {
                            key: index,
                            staticClass: "mb-2",
                            attrs: {
                              color: "light-blue lighten-4",
                              rounded: "",
                              icon: "mdi-alert",
                            },
                          },
                          [_vm._v(_vm._s(message))]
                        )
                      }),
                ],
                2
              )
            : _c("v-col"),
          _c("div", { staticClass: "mx-6" }),
          _vm.tripRequest.destinationId && _vm.hasReturnLeg
            ? _c(
                "v-col",
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("Return Trip"),
                  ]),
                  _vm.loading
                    ? [
                        _c("v-progress-circular", {
                          attrs: {
                            size: 30,
                            color: "primary",
                            indeterminate: "",
                          },
                        }),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _vm._v("Calculating route..."),
                        ]),
                      ]
                    : _vm.successReturn
                    ? [
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _c("strong", [_vm._v("Miles to Return:")]),
                          _vm._v(" " + _vm._s(_vm.rMileage)),
                        ]),
                        _c("div", { staticClass: "text-subtitle-1" }, [
                          _c("strong", [_vm._v("Drive Time to Return:")]),
                          _vm._v(" " + _vm._s(_vm.rTime)),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", width: "100%" },
                            on: {
                              click: function ($event) {
                                return _vm.showDirections(1)
                              },
                            },
                          },
                          [_vm._v("Return Directions")]
                        ),
                      ]
                    : _vm._l(_vm.returnErrors, function (message, index) {
                        return _c(
                          "v-banner",
                          {
                            key: index,
                            staticClass: "mb-2",
                            attrs: {
                              color: "light-blue lighten-4",
                              rounded: "",
                              icon: "mdi-alert",
                            },
                          },
                          [_vm._v(_vm._s(message))]
                        )
                      }),
                ],
                2
              )
            : _c("v-col"),
        ],
        1
      ),
      _c("directions", {
        ref: "directions",
        attrs: {
          destinationId: _vm.tripRequest.destinationId,
          originId: _vm.tripRequest.locationId,
          waypoints: _vm.waypoints,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }