var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        { on: { submit: _vm.save } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  label: "Add a comment",
                                  value: _vm.form.comment,
                                  required: "",
                                  "append-icon": "mdi-content-save",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    return _vm.save()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.comment.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.$v.form.comment.$model,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.$v.form.comment, "$model", $$v)
                                  },
                                  expression: "$v.form.comment.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  "fixed-header": "",
                  headers: _vm.headers,
                  items: _vm.comments,
                  "item-key": "id",
                  "sort-by": "time",
                  "disable-sort": "",
                  "no-data-text": "No comments yet!",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.time`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  new Date(item.timestamp * 1000),
                                  "PP p"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }