var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-form",
            {
              ref: "form2",
              staticClass: "full-width",
              attrs: { readonly: _vm.readonly },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          !_vm.tripRequest.status ||
                          _vm.tripRequest.status == -1
                            ? _c("span", { staticClass: "red--text" }, [
                                _c("small", [_vm._v("*Required")]),
                              ])
                            : _vm._e(),
                          _c("date-picker", {
                            ref: "leaveDate",
                            attrs: {
                              min: _vm.minDate,
                              readonly: _vm.readonly && !_vm.canEditDates,
                              rules: [
                                () =>
                                  !!_vm.tripRequest.leaveDate ||
                                  "This field is required",
                              ],
                              label: "Leave Date",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setOtherDates($event, "leaveDate")
                              },
                            },
                            model: {
                              value: _vm.tripRequest.leaveDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "leaveDate", $$v)
                              },
                              expression: "tripRequest.leaveDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          !_vm.tripRequest.status ||
                          _vm.tripRequest.status == -1
                            ? _c("span", { staticClass: "red--text" }, [
                                _c("small", [_vm._v("*Required")]),
                              ])
                            : _vm._e(),
                          _c("time-picker", {
                            ref: "leaveTime",
                            attrs: {
                              readonly: _vm.readonly && !_vm.canEditDates,
                              rules: [
                                () =>
                                  !!_vm.tripRequest.leaveTime ||
                                  "This field is required",
                              ],
                              label: "Leave Time",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setOtherDates($event, "leaveTime")
                              },
                            },
                            model: {
                              value: _vm.tripRequest.leaveTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "leaveTime", $$v)
                              },
                              expression: "tripRequest.leaveTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          !_vm.tripRequest.status ||
                          _vm.tripRequest.status == -1
                            ? _c("span", { staticClass: "red--text" }, [
                                _c("small", [_vm._v("*Required")]),
                              ])
                            : _vm._e(),
                          _c("date-picker", {
                            ref: "returnDate",
                            attrs: {
                              min: _vm.minDate,
                              readonly: _vm.readonly && !_vm.canEditDates,
                              rules: [
                                () =>
                                  !!_vm.tripRequest.returnDate ||
                                  "This field is required",
                              ],
                              label: "Return Date",
                              required: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setOtherDates($event, "returnDate")
                              },
                            },
                            model: {
                              value: _vm.tripRequest.returnDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "returnDate", $$v)
                              },
                              expression: "tripRequest.returnDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          !_vm.tripRequest.status ||
                          _vm.tripRequest.status == -1
                            ? _c("span", { staticClass: "red--text" }, [
                                _c("small", [_vm._v("*Required")]),
                              ])
                            : _vm._e(),
                          _c("time-picker", {
                            ref: "returnTime",
                            attrs: {
                              readonly: _vm.readonly && !_vm.canEditDates,
                              rules: [
                                () =>
                                  !!_vm.tripRequest.returnTime ||
                                  "This field is required",
                              ],
                              label: "Return Time",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setOtherDates($event, "returnTime")
                              },
                            },
                            model: {
                              value: _vm.tripRequest.returnTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "returnTime", $$v)
                              },
                              expression: "tripRequest.returnTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _vm.tripRequest.status == -1 || _vm.tripRequest.status == 0
                    ? _vm._l([..._vm.activeMessages], function (am) {
                        return _c(
                          "v-row",
                          { key: am.id, attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "12" } },
                              [
                                _c(
                                  "v-alert",
                                  {
                                    attrs: {
                                      outlined: "",
                                      type: am.alertType || "info",
                                      text: "",
                                    },
                                  },
                                  [_vm._v(_vm._s(am.message))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          (!_vm.tripRequest.status ||
                            _vm.tripRequest.status == -1) &&
                          _vm.tripRequestConfig.display.overnightOOSRequired
                            ? _c("span", { staticClass: "red--text" }, [
                                _c("small", [_vm._v("*Required")]),
                              ])
                            : _vm._e(),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-0",
                              attrs: { readonly: _vm.readonly },
                              on: { change: _vm.handleOOSChange },
                              scopedSlots: _vm._u([
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tripRequestConfig.labels
                                              .overnightOOS
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.tripRequest.outOfState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tripRequest,
                                    "outOfState",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "tripRequest.outOfState",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: 1 },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _c(
                                            "strong",
                                            { staticClass: "success--text" },
                                            [_vm._v("Yes")]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("v-radio", {
                                attrs: { value: 0 },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _c(
                                            "strong",
                                            { staticClass: "primary--text" },
                                            [_vm._v("No")]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.tripRequest.outOfState ||
                      (_vm.tripRequestConfig.display.outOfCounty &&
                        _vm.tripRequestConfig.display.outOfCountyTypes.includes(
                          _vm.tripRequest.tripTypeId
                        ))
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              (!_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1) &&
                              _vm.tripRequestConfig.display.overnightOOSRequired
                                ? _c("span", { staticClass: "white--text" }, [
                                    _c("small", [_vm._v("*")]),
                                  ])
                                : _vm._e(),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-0",
                                  attrs: { readonly: _vm.readonly },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tripRequestConfig.labels
                                                    .outOfCounty
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2873800566
                                  ),
                                  model: {
                                    value: _vm.tripRequest.outOfCounty,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "outOfCounty",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tripRequest.outOfCounty",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { value: 1 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "success--text",
                                                  },
                                                  [_vm._v("Yes")]
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3793165732
                                    ),
                                  }),
                                  _c("v-radio", {
                                    attrs: { value: 0 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [_vm._v("No")]
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2475461701
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tripRequestConfig.display.actualTimeTBD
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-0",
                                  attrs: { readonly: _vm.readonly },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("div", [
                                              _vm._v("Actual Time TBD"),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2542923422
                                  ),
                                  model: {
                                    value: _vm.tripRequest.actualTimeTBD,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "actualTimeTBD",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tripRequest.actualTimeTBD",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { value: 1 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "success--text",
                                                  },
                                                  [_vm._v("Yes")]
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3793165732
                                    ),
                                  }),
                                  _c("v-radio", {
                                    attrs: { value: 0 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [_vm._v("No")]
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2475461701
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.customFormFields
                    ? _c(
                        "v-row",
                        _vm._l(_vm.customFormFields, function (cff, i) {
                          return _c("custom-form-field", {
                            key: i,
                            ref: cff.id,
                            refInFor: true,
                            attrs: {
                              cff: cff,
                              value: _vm.tripRequest.customFormFields[cff.id],
                              readonly: _vm.readonly,
                            },
                            on: {
                              handleCFFInput: function ($event) {
                                return _vm.$emit("handleCFFInput", {
                                  cffId: cff.id,
                                  value: $event,
                                })
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.showRecurringTrips
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.disableRecurring,
                                  },
                                  on: { click: _vm.openRecurringTrip },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.tripRequest.recurrence &&
                                          _vm.tripRequest.recurrence.length > 0
                                          ? "Edit"
                                          : "Create"
                                      ) +
                                      " Recurring Trips "
                                  ),
                                ]
                              ),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.tripRequestConfig.messages
                                      .recurringButton
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tripRequest.recurrence &&
                  _vm.tripRequest.recurrence.length
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    outlined: "",
                                    type: "info",
                                    text: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Dates selected: " +
                                      _vm._s(
                                        _vm.tripRequest.recurrence
                                          .map((e) => _vm.readableDate(e))
                                          .join(", ")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.tripRequest.status == 0
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        outlined: "",
                                        type: "warning",
                                        text: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Recurring trips are not created until this trip request is approved "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("recurring-picker", {
        ref: "rpicker",
        attrs: {
          leaveDate: _vm.tripRequest.leaveDate,
          recurrence: _vm.tripRequest.recurrence,
          tripRequest: _vm.tripRequest,
        },
        on: {
          refresh: function ($event) {
            return _vm.$emit("refresh")
          },
          save: function ($event) {
            return _vm.$emit("save")
          },
          setRecurrence: _vm.setRecurrence,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }