<template>
  <v-card outlined :loading="isLoadingFAD">
    <v-card-title>
      {{ isDeclined ? 'Declined ' : '' }}Assignment #{{ index + 1 }}
      <v-chip
        v-if="driverConfig.driverAccept || driverConfig.driverDecline"
        :color="getAssignmentStatus(assignment)?.color"
        class="font-weight-bold ml-4"
        label
      >
        {{ getAssignmentStatus(assignment)?.text }}
      </v-chip>

      <v-icon v-if="assignment.leg > 0" class="ml-4" :color="assignment.leg == 1 ? 'green' : 'blue'">
        {{ assignment.leg == 1 ? 'mdi-arrow-right-bold-circle' : 'mdi-arrow-left-bold-circle' }}
      </v-icon>

      <p v-if="assignment.assignedAt" class="mb-0 ml-4 text-subtitle-2 inline">{{ assignedBy }}</p>

      <v-spacer></v-spacer>

      <v-btn text color="primary" v-if="showSendDriverEmail" @click="sendDriverEmail()">
        <v-icon>mdi-email</v-icon>Send Driver Notification
      </v-btn>

      <trip-ticket-download-button v-if="hasTripTicketTemplate && !isDeclined" :assignmentIds="[assignment.id]" />

      <v-btn text color="error" @click="removeAssignment(assignment.id)" v-if="isAdminOrVehicleOwner && !isDeclined">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row dense v-if="assignment.notes">
        <v-col cols="12">
          <p class="mb-0">{{ assignment.notes }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :md="assignment.assistantId ? '4' : '6'">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="40px"></th>
                  <th class="text-left">Vehicle</th>
                  <th class="text-left">Vehicle Location</th>
                </tr>
              </thead>

              <tbody>
                <tr v-if="assignment.vehicleId || assignment.vehicle">
                  <td>
                    <v-btn
                      icon
                      color="error"
                      @click="removeVehicle(assignment)"
                      v-if="tripRequest.vehicleType === VEHICLE_TYPES.NORMAL && isAdminOrVehicleOwner && !isDeclined"
                      :disabled="isLoading"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>

                  <td>{{ getVehicle(assignment) }}</td>
                  <td>{{ getVehicleLocation(assignment.vehicleId) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" md="6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="40px"></th>
                  <th class="text-left">Driver</th>
                  <th class="text-left">Email</th>
                  <th width="40px" v-if="driverConfig.driverAccept && assignment.status === 1 && isAdmin">Accept</th>
                  <th width="40px" v-if="driverConfig.driverDecline && assignment.status === 1 && isAdmin">Decline</th>
                </tr>
              </thead>

              <tbody
                v-if="
                  driverConfig.useAvailableDrivers &&
                  tripRequest.needDriverAssigned &&
                  (assignment.driver || assignment.driverId)
                "
              >
                <tr>
                  <td>
                    <v-btn
                      icon
                      color="error"
                      @click="removeDriver(assignment)"
                      v-if="tripRequest.vehicleType === VEHICLE_TYPES.NORMAL && isAdminOrVehicleOwner && !isDeclined"
                      :disabled="isLoading"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>

                  <td>
                    <template v-if="!assignment.driverId">
                      {{ assignment.driver }}
                    </template>
                    <template v-else>
                      {{ driversById[assignment.driverId].firstName }}
                      {{ driversById[assignment.driverId].lastName }}
                    </template>
                  </td>
                  <td>
                    {{ getDriverEmail({ driverId: assignment.driverId, driverEmail: assignment.driverEmail }) }}
                  </td>
                  <td v-if="driverConfig.driverAccept && assignment.status === 1 && isAdmin">
                    <v-btn icon color="primary" @click="adminAccept(assignment.id)">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="driverConfig.driverDecline && assignment.status === 1 && isAdmin">
                    <v-btn icon color="primary" @click="$emit('adminDecline', assignment.id)">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row v-if="!driverConfig.useAvailableDrivers || !tripRequest.needDriverAssigned" class="mt-2">
            <v-col cols="12" md="1">
              <v-btn
                v-if="showDriverDeleteButton"
                icon
                class="mt-3"
                color="error"
                @click="removeDriver(assignment)"
                :disabled="isLoading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>

            <template v-if="!driverConfig.useAvailableDrivers">
              <v-col cols="10" md="5">
                <v-combobox
                  v-model="assignment.driver"
                  :items="driverItems"
                  :item-text="getDriverText"
                  label="Driver"
                  return-object
                  outlined
                  @change="handleDriverChange"
                  :disabled="tripRequest.vehicleType === VEHICLE_TYPES.APPROVED_CHARTER || !canAssign"
                />
              </v-col>

              <v-col cols="10" md="5">
                <v-text-field
                  v-model="assignment.driverEmail"
                  label="Driver Email"
                  outlined
                  :disabled="tripRequest.vehicleType === VEHICLE_TYPES.APPROVED_CHARTER || !canAssign"
                />
              </v-col>

              <v-col cols="auto">
                <v-btn
                  text
                  color="success"
                  class="mt-3"
                  :loading="isLoading"
                  :disabled="!canAssign || assignment.enableSave === false"
                  @click="$emit('reserve', [assignment])"
                  icon
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </template>

            <template v-else>
              <v-col>
                {{ assignment.driver || getDriverText(driversById[assignment.driverId]) }}
              </v-col>

              <v-col>
                {{ assignment.driverEmail || driversById[assignment.driverId]?.email }}
              </v-col>
            </template>
          </v-row>

          <v-row v-if="tripRequest.vehicleType !== VEHICLE_TYPES.NORMAL" class="mt-2">
            <v-col cols="12" md="1">
              <v-btn
                icon
                class="mt-3"
                color="error"
                @click="$emit('removeMechanic', assignment)"
                v-if="
                  (me.is.superAdmin ||
                    me.is.transportationAdmin ||
                    me.is.vehicleOwner ||
                    me.is.specialNeedsVehicleOwner) &&
                  (assignment.mechanic || assignment.mechanicEmail)
                "
                :disabled="isLoading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="10" md="5" v-if="mechanicItems?.length">
              <v-combobox
                v-model="assignment.mechanic"
                :value="assignment.mechanic"
                :items="mechanicItems"
                :item-text="getMechanicText"
                label="Mechanic"
                return-object
                outlined
                @change="handleMechanicAdded($event, assignment)"
                @update:search-input="handleMechanicAdded($event, assignment)"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="5">
              <v-text-field
                v-model="assignment.mechanicEmail"
                :value="assignment.mechanicEmail"
                label="Mechanic Email"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn
                icon
                class="mt-3"
                color="success"
                @click="$emit('saveAssignmentMechanic', [assignment])"
                :disabled="isLoading || !canAssign"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="2" v-if="assignment.assistantId">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="40px"></th>
                  <th class="text-left">{{ assistantLabel }}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <v-btn
                      icon
                      color="error"
                      @click="removeAssistant(assignment)"
                      v-if="isAdminOrVehicleOwner && !isDeclined"
                      :disabled="isLoading"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>

                  <td>
                    {{ driversById[assignment.assistantId].firstName }}
                    {{ driversById[assignment.assistantId].lastName }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row v-if="tripRequest.vehicleType === VEHICLE_TYPES.NORMAL && !isDeclined">
        <v-col cols="12" :md="assignment.assistantId ? '4' : '6'">
          <v-btn
            v-if="!assignment.vehicleId"
            color="primary"
            @click="$emit('openFAV', assignment.id, assignment.leg)"
            :disabled="!canAssign"
          >
            {{ favText }}
          </v-btn>

          <v-btn
            v-else
            color="primary"
            @click="$emit('openFAV', assignment.id, assignment.leg)"
            :disabled="!canAssign || me.is.schoolFinance"
            :loading="isLoadingVehicle"
          >
            Change Vehicle
            <template v-slot:loader>
              <v-progress-circular indeterminate></v-progress-circular>
            </template>
          </v-btn>
        </v-col>

        <v-col cols="12" md="6" v-if="driverConfig.useAvailableDrivers">
          <v-btn
            v-if="!assignment.driverId && !assignment.driver"
            color="primary"
            @click="$emit('openFAD', assignment.id)"
            :disabled="!canAssign || me.is.schoolFinance"
          >
            {{ fadText }}
          </v-btn>

          <template v-else-if="tripRequest.needDriverAssigned">
            <v-btn
              color="primary"
              @click="$emit('openFAD', assignment.id)"
              :disabled="!canAssign && !schoolFinanceDriverEditable"
              :loading="isLoading"
            >
              Change Driver
              <template v-slot:loader>
                <v-progress-circular indeterminate></v-progress-circular>
              </template>
            </v-btn>
          </template>

          <template v-else-if="!tripRequest.needDriverAssigned">
            <v-btn color="primary" @click="$emit('openFAD', assignment.id)" :disabled="!canAssign" :loading="isLoading">
              Change Driver
              <template v-slot:loader>
                <v-progress-circular indeterminate></v-progress-circular>
              </template>
            </v-btn>
          </template>

          <v-btn
            v-if="assignment.driverId && !assignment.assistantId && isAdminOrVehicleOwner"
            class="ml-4"
            color="primary"
            @click="$emit('openFAD', assignment.id, 0, true)"
            :disabled="!canAssign"
          >
            Add {{ assistantLabel }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="2" v-if="assignment.assistantId">
          <v-btn color="primary" @click="$emit('openFAD', assignment.id, 0, true)" :disabled="!canAssign">
            Change {{ assistantLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';

import {
  SAVE_ASSIGNMENT,
  ACCEPT_ASSIGNMENT,
  DELETE_ASSIGNMENT,
  SEND_DRIVER_NOTIFICATION,
} from '@/store/modules/Assignment/actions';

import { ASSIGNMENT_STATUS } from '@/shared/common';
import VEHICLE_TYPES from '@/shared/types';
import { getVehicleOwner, getVehicleLocation, inputProps } from '@/util';

import TripTicketDownloadButton from '@/components/TripTicketDownloadButton.vue';

const FAV_TEXT = 'Find Available Vehicles';
const FAD_TEXT = 'Find Available Drivers';

export default {
  name: 'TripRequestAssignmentCard',
  components: { TripTicketDownloadButton },
  props: {
    assignment: Object,
    tripRequest: Object,
    index: Number,
    isLoading: Boolean,
    isLoadingFAD: Boolean,
    isLoadingVehicle: Boolean,
    driverItems: Array,
    mechanicItems: Array,
  },
  data() {
    return {
      VEHICLE_TYPES,
      getVehicleLocation,
      fadText: FAD_TEXT,
      favText: FAV_TEXT,
    };
  },
  computed: {
    ...mapGetters('app', ['hasTripTicketTemplate']),
    ...mapGetters('config', ['driverConfig', 'schoolFinanceDriverEditable']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('user', ['me', 'isAdmin', 'usersById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('tripRequest', ['currentTripRequest']),
    assignedBy() {
      const user = this.usersById[this.assignment.assignedBy];
      const formattedDate = format(new Date(this.assignment.assignedAt * 1000), 'MMM d, yyyy @ h:mm aaa');
      return `${user ? user.displayName + ' - ' : ''}${formattedDate}`;
    },
    canAssign() {
      // Requester has no editable permission in assignments
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner ||
        this.tripRequest.permissions.canAssign
      );
    },
    isDeclined() {
      return (
        this.assignment.status == ASSIGNMENT_STATUS.ADMIN_DECLINED ||
        this.assignment.status == ASSIGNMENT_STATUS.SELF_DECLINED
      );
    },
    isAdminOrVehicleOwner() {
      return this.isAdmin || this.me.is.vehicleOwner || this.me.is.specialNeedsVehicleOwner;
    },
    showDriverDeleteButton() {
      const isNormalVehicle = this.tripRequest.vehicleType === this.VEHICLE_TYPES.NORMAL;
      const needsNoDriver = !this.tripRequest.needDriverAssigned;

      const hasAdminPrivileges =
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner;

      const hasAssignedDriver = Boolean(
        this.assignment.driverId || this.assignment.driver || this.assignment.driverEmail
      );

      return isNormalVehicle && needsNoDriver && hasAdminPrivileges && hasAssignedDriver;
    },
    showSendDriverEmail() {
      return (
        this.isAdmin &&
        (this.driverConfig.driverAccept || this.driverConfig.driverDecline) &&
        this.assignment.status === ASSIGNMENT_STATUS.PENDING
      );
    },
    assistantLabel() {
      return this.driverConfig?.driverAssistantLabel || 'Assistant';
    },
  },
  methods: {
    ...mapActions('assignment', [SAVE_ASSIGNMENT, ACCEPT_ASSIGNMENT, DELETE_ASSIGNMENT, SEND_DRIVER_NOTIFICATION]),
    ...mapMutations('assignment', ['updateAssignment', 'removeAssignmentById']),
    handleDriverChange(driver) {
      this.assignment.enableSave = true;
      this.$myalert.close();

      if (!driver.id) return;
      if (this.currentTripRequest?.assignments.some((a) => a.driverId === driver.id)) {
        this.$myalert.error('Driver is already assigned to another trip.', true);
        this.assignment.enableSave = false;
      }

      this.assignment.driverId = driver.id;
      this.setDriverEmail(this.assignment);
    },
    async saveAssignmentMechanic(objs) {
      this.isLoading = true;
      this.reserving = true;
      try {
        for (let obj of objs) {
          const response = await this[SAVE_ASSIGNMENT](obj);
          if (response?.id && obj.mechanic && obj.mechanicEmail && !obj.mechanicId) {
            await this.saveMechanic({ name: obj.mechanic, email: obj.mechanicEmail });
          }
        }
        this.$myalert.success(`Assignment${objs.length > 1 ? 's' : ''} saved`);
        this.$emit('refresh');
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.isLoading = false;
        this.reserving = false;
      }
    },
    async sendDriverEmail() {
      try {
        const ok = await this.$myconfirm('Are you sure you want to send the notification email to this driver?');
        if (ok) {
          await this.sendDriverNotification(this.assignment.id);
          this.$myalert.success('Notification sent');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async removeAssignment(id, override) {
      const ok = override ? true : await this.$myconfirm('Are you sure you want to remove this assignment?');
      if (ok)
        try {
          this.isLoading = true;
          await this.deleteAssignment(id);
          this.$myalert.success('Assignment deleted');
          this.$emit('refresh');
          this.removeAssignmentById(id);
        } catch (error) {
          this.$myalert.error(error.message);
        } finally {
          this.isLoading = false;
        }
    },
    async removeDriver(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this driver?');
      if (ok)
        if (!assignment.vehicleId) await this.removeAssignment(assignment.id, true);
        else
          try {
            const obj = {
              id: assignment.id,
              driverId: 0,
              driver: null,
              driverEmail: null,
              removed: true,
              raw: true,
              removeDriver: true,
            };
            await this[SAVE_ASSIGNMENT](obj);
            this.$myalert.success('Driver removed');
            this.$emit('refresh');
            await this.updateAssignment({ ...assignment, driverId: 0, driver: null, driverEmail: null });
          } catch (error) {
            this.$myalert.error(error.message);
          }
    },
    async removeVehicle(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this vehicle?');
      if (ok)
        if (!assignment.driverId && !assignment.driver) await this.removeAssignment(assignment.id, true);
        else
          try {
            this.isLoading = true;
            const obj = {
              id: assignment.id,
              vehicleId: 0,
              raw: true,
              removeVehicle: true,
            };
            await this[SAVE_ASSIGNMENT](obj);
            this.$myalert.success('Vehicle removed');
            this.$emit('refresh');
            await this.updateAssignment({ ...assignment, vehicleId: 0 });
          } catch (error) {
            this.$myalert.error(error.message);
          } finally {
            this.isLoading = false;
          }
    },
    async removeAssistant(assignment) {
      const ok = await this.$myconfirm(`Are you sure you want to remove this ${this.assistantLabel}?`);
      if (ok)
        try {
          const obj = {
            id: assignment.id,
            assistantId: 0,
            removed: true,
            raw: true,
            removeAssistant: true,
          };
          await this[SAVE_ASSIGNMENT](obj);
          this.$myalert.success(`${this.assistantLabel} removed`);
          this.$emit('refresh');
          await this.updateAssignment({ ...assignment, assistantId: 0 });
        } catch (error) {
          this.$myalert.error(error.message);
        }
    },
    async removeMechanic(assignment) {
      const ok = await this.$myconfirm('Are you sure you want to remove this mechanic?');
      if (ok)
        try {
          const obj = {
            id: assignment.id,
            mechanic: null,
            mechanicEmail: null,
            removed: true,
            raw: true,
          };
          await this[SAVE_ASSIGNMENT](obj);
          this.$myalert.success('Mechanic removed');
          this.$emit('refresh');
          await this.updateAssignment({ ...assignment, mechanic: null, mechanicEmail: null });
        } catch (error) {
          this.$myalert.error(error.message);
        }
    },
    async adminAccept(assignmentId) {
      try {
        const ok = await this.$myconfirm('Are you sure you want to accept this assignment on behalf of the driver?');
        if (ok) {
          await this.acceptAssignment({ assignmentId, status: ASSIGNMENT_STATUS.ADMIN_ACCEPTED });
          this.$myalert.success('Assignment accepted successfully');
          this.$emit('refresh');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    handleMechanicAdded(mechanic, assignment) {
      if (mechanic?.id) {
        assignment.mechanic = mechanic.name;
        assignment.mechanicEmail = mechanic.email;
        assignment.mechanicId = mechanic.id;
      }
    },
    getVehicle(assignment) {
      if (assignment.vehicleId) return this.vehiclesById[assignment.vehicleId].name;
      else return assignment.vehicle;
    },
    getDriverText(driver) {
      return [driver?.firstName, driver?.lastName].join(' ').trim();
    },
    getDriverEmail({ driverId, driverEmail }) {
      if (driverEmail) return driverEmail;
      if (!driverId) return null;
      const driver = this.driversById[driverId];
      if (!driver) return null;
      return driver.email;
    },
    setDriverEmail(assignment) {
      if (!assignment.driver.id) return;
      assignment.driverEmail = assignment.driver.email;
    },
    getAssignmentStatus(assignment) {
      switch (assignment.status) {
        case ASSIGNMENT_STATUS.NONE:
          return { text: 'No driver assigned', color: 'gray' };
        case ASSIGNMENT_STATUS.PENDING:
          return { text: 'Driver Pending', color: 'gray' };
        case ASSIGNMENT_STATUS.SELF_ACCEPTED:
          return { text: 'Self Accepted', color: 'success' };
        case ASSIGNMENT_STATUS.ADMIN_ACCEPTED:
          return { text: 'Admin Accepted', color: 'success' };
        case ASSIGNMENT_STATUS.SELF_DECLINED:
          return { text: 'Self Declined', color: 'error' };
        case ASSIGNMENT_STATUS.ADMIN_DECLINED:
          return { text: 'Admin Declined', color: 'error' };
      }
    },
  },
};
</script>

<style lang="scss"></style>
