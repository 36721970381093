var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "transportationForm",
          staticClass: "full-width",
          model: {
            value: _vm.isFormValid,
            callback: function ($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid",
          },
        },
        [
          _vm.tripRequestConfig.display.extTransportation
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "info", text: "" } },
                        [
                          _c("div", [
                            _vm._v(
                              'At least one option should be selected as "Yes".'
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tripRequestConfig.display.extTransportation
            ? _c(
                "v-row",
                { staticClass: "align-baseline", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-label", [
                        _vm._v(
                          _vm._s(_vm.tripRequestConfig.labels.extTransportation)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center justify-center" },
                      [
                        _c("span", [_vm._v("No")]),
                        _c("v-switch", {
                          staticClass: "ml-4",
                          attrs: {
                            "true-value": 1,
                            "false-value": 0,
                            inset: "",
                            readonly:
                              _vm.isReadonly ||
                              _vm.isTripApprovedAndSubmittedByRequester,
                          },
                          model: {
                            value: _vm.tripRequest.needExternalTransportation,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tripRequest,
                                "needExternalTransportation",
                                _vm._n($$v)
                              )
                            },
                            expression:
                              "tripRequest.needExternalTransportation",
                          },
                        }),
                        _c("span", [_vm._v("Yes")]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tripRequest.needExternalTransportation,
                          expression: "tripRequest.needExternalTransportation",
                        },
                      ],
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder:
                            _vm.tripRequestConfig.labels.extTransportationMode,
                          dense: "",
                          "persistent-placeholder": "",
                          outlined: "",
                          rows: "3",
                          readonly:
                            _vm.isReadonly ||
                            _vm.isTripApprovedAndSubmittedByRequester,
                        },
                        model: {
                          value: _vm.tripRequest.extTransportationDetail,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tripRequest,
                              "extTransportationDetail",
                              $$v
                            )
                          },
                          expression: "tripRequest.extTransportationDetail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tripRequestConfig.display.extTransportation
            ? _c(
                "v-row",
                { staticClass: "align-baseline", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-label", [
                        _vm._v(
                          _vm._s(_vm.tripRequestConfig.labels.vehiclesNeeded)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center justify-center" },
                      [
                        _c("span", [_vm._v("No")]),
                        _c("v-switch", {
                          staticClass: "ml-4",
                          attrs: {
                            "true-value": 1,
                            "false-value": 0,
                            inset: "",
                            readonly:
                              _vm.readonly &&
                              !_vm.tripRequest.permissions.canEditSiteAdmin,
                            "data-cy": "trip-vehicles-needed",
                          },
                          on: { change: _vm.onVehiclesNeededChange },
                          model: {
                            value: _vm.tripRequest.needDistrictVehicles,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tripRequest,
                                "needDistrictVehicles",
                                _vm._n($$v)
                              )
                            },
                            expression: "tripRequest.needDistrictVehicles",
                          },
                        }),
                        _c("span", [_vm._v("Yes")]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tripRequest.needDistrictVehicles &&
                    _vm.tripRequestConfig.display.driverAssigned,
                  expression:
                    "tripRequest.needDistrictVehicles && tripRequestConfig.display.driverAssigned",
                },
              ],
              staticClass: "align-baseline",
              attrs: { dense: "" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-label", [
                    _vm._v(_vm._s(_vm.tripRequestConfig.labels.driverAssigned)),
                  ]),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center" },
                  [
                    _c(
                      "v-radio-group",
                      {
                        ref: "needDriverAssigned",
                        staticClass: "ml-4",
                        attrs: {
                          row: "",
                          required:
                            _vm.tripRequestConfig.display.requireDriverInfo,
                          readonly:
                            _vm.isReadonly ||
                            _vm.isTripApprovedAndSubmittedByRequester,
                          rules: [
                            () =>
                              !(
                                _vm.tripRequestConfig.display
                                  .requireDriverInfo &&
                                _vm.tripRequest.needDriverAssigned == null
                              ) || "This field is required",
                          ],
                        },
                        model: {
                          value: _vm.tripRequest.needDriverAssigned,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripRequest, "needDriverAssigned", $$v)
                          },
                          expression: "tripRequest.needDriverAssigned",
                        },
                      },
                      [
                        _c("v-radio", { attrs: { label: "No", value: 0 } }),
                        _c("v-radio", { attrs: { label: "Yes", value: 1 } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canEnterDriverName,
                  expression: "canEnterDriverName",
                },
              ],
              attrs: { dense: "" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _vm.canEnterDriverName
                    ? _c("v-textarea", {
                        attrs: {
                          required:
                            _vm.tripRequestConfig.display.requireDriverInfo,
                          label: _vm.tripRequestConfig.labels.vehDriverInfo,
                          dense: "",
                          outlined: "",
                          rows: "3",
                          readonly:
                            _vm.readonly &&
                            !_vm.tripRequest.permissions.canEditSiteAdmin,
                          rules: [
                            (v) =>
                              (_vm.tripRequestConfig.display.requireDriverInfo
                                ? !_vm.tripRequest.vehDriverInfo.length == 0
                                : true) || "Driver Info is required",
                          ],
                        },
                        model: {
                          value: _vm.tripRequest.vehDriverInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripRequest, "vehDriverInfo", $$v)
                          },
                          expression: "tripRequest.vehDriverInfo",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tripRequest.needDistrictVehicles,
                      expression: "tripRequest.needDistrictVehicles",
                    },
                  ],
                  attrs: { cols: "12", md: "3" },
                },
                [
                  _c("div", { staticClass: "required-div" }, [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("v-select", {
                    ref: "vehicleTypeId",
                    attrs: {
                      "menu-props": { bottom: true, offsetY: true },
                      items: _vm.vehicleTypeItems,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Vehicle Type",
                      outlined: "",
                      rules: [
                        () =>
                          !!_vm.tripRequest.vehicleTypeId ||
                          "This field is required",
                      ],
                      required: "",
                      hint: _vm.noAvailableVehiclesHint,
                      "persistent-hint": !!_vm.noAvailableVehiclesHint,
                      readonly:
                        _vm.isReadonly ||
                        _vm.isTripApprovedAndSubmittedByRequester,
                      "data-cy": "trip-vehicle-type",
                    },
                    on: { change: _vm.handleVehicleTypeSelected },
                    model: {
                      value: _vm.tripRequest.vehicleTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.tripRequest, "vehicleTypeId", $$v)
                      },
                      expression: "tripRequest.vehicleTypeId",
                    },
                  }),
                ],
                1
              ),
              _vm.tripRequest.needDistrictVehicles &&
              _vm.tripRequest.vehicleType ==
                _vm.VEHICLE_TYPES.APPROVED_CHARTER &&
              (_vm.tripRequest.status != 0 ||
                _vm.tripRequestConfig.display.contractors)
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("div", { staticClass: "required-div" }, [
                        !_vm.tripRequest.status || _vm.tripRequest.status == -1
                          ? _c("span", { staticClass: "red--text" }, [
                              _c("small", [_vm._v("*Required")]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("v-select", {
                        ref: "additionalTransportationId",
                        attrs: {
                          "menu-props": { bottom: true, offsetY: true },
                          items: _vm.charters,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Approved Charter",
                          outlined: "",
                          rules: _vm.tripRequestConfig.display
                            .requireContractors
                            ? [
                                () =>
                                  !!_vm.tripRequest
                                    .additionalTransportationId ||
                                  "This field is required",
                              ]
                            : [],
                          required: "",
                          readonly:
                            _vm.readonly &&
                            !_vm.tripRequest.permissions.canEditSiteAdmin,
                        },
                        model: {
                          value: _vm.tripRequest.additionalTransportationId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tripRequest,
                              "additionalTransportationId",
                              $$v
                            )
                          },
                          expression: "tripRequest.additionalTransportationId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tripRequest.needDistrictVehicles &&
              _vm.tripRequest.vehicleType == _vm.VEHICLE_TYPES.CONTRACTOR &&
              (_vm.tripRequest.status != 0 ||
                _vm.tripRequestConfig.display.contractors)
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("div", { staticClass: "required-div" }, [
                        !_vm.tripRequest.status || _vm.tripRequest.status == -1
                          ? _c("span", { staticClass: "red--text" }, [
                              _c("small", [_vm._v("*Required")]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("v-select", {
                        ref: "additionalTransportationId",
                        attrs: {
                          "menu-props": { bottom: true, offsetY: true },
                          items: _vm.contractors,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Contractor",
                          outlined: "",
                          rules: _vm.tripRequestConfig.display
                            .requireContractors
                            ? [
                                () =>
                                  !!_vm.tripRequest
                                    .additionalTransportationId ||
                                  "This field is required",
                              ]
                            : [],
                          required: "",
                          readonly:
                            _vm.readonly &&
                            !_vm.tripRequest.permissions.canEditSiteAdmin,
                        },
                        model: {
                          value: _vm.tripRequest.additionalTransportationId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tripRequest,
                              "additionalTransportationId",
                              $$v
                            )
                          },
                          expression: "tripRequest.additionalTransportationId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tripRequest.needDistrictVehicles &&
                        _vm.tripRequest.vehicleType ==
                          _vm.VEHICLE_TYPES.RENTAL_DEALERSHIP &&
                        (_vm.tripRequest.status != 0 ||
                          _vm.tripRequestConfig.display.contractors),
                      expression:
                        "\n          tripRequest.needDistrictVehicles &&\n          tripRequest.vehicleType == VEHICLE_TYPES.RENTAL_DEALERSHIP &&\n          (tripRequest.status != 0 || tripRequestConfig.display.contractors)\n        ",
                    },
                  ],
                  attrs: { cols: "12", md: "3" },
                },
                [
                  _c("div", { staticClass: "required-div" }),
                  _c("v-select", {
                    attrs: {
                      "menu-props": { bottom: true, offsetY: true },
                      items: _vm.rentals,
                      "item-text": "name",
                      "item-value": "id",
                      label: "Rental/Dealership Agency",
                      outlined: "",
                      readonly:
                        _vm.readonly &&
                        !_vm.tripRequest.permissions.canEditSiteAdmin,
                    },
                    model: {
                      value: _vm.tripRequest.additionalTransportationId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tripRequest,
                          "additionalTransportationId",
                          $$v
                        )
                      },
                      expression: "tripRequest.additionalTransportationId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tripRequest.needDistrictVehicles,
                      expression: "tripRequest.needDistrictVehicles",
                    },
                  ],
                  attrs: { cols: "12", md: "2" },
                },
                [
                  _c("div", { staticClass: "required-div" }, [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                  ]),
                  _c("v-text-field", {
                    ref: "numVehicles",
                    attrs: {
                      label: "Number of Vehicles Needed",
                      type: "number",
                      min: "0",
                      hint: _vm.tripRequestConfig.messages.studentsPerVehicle,
                      rules: [
                        () =>
                          !!_vm.tripRequest.numVehicles ||
                          "This field is required",
                      ],
                      required: "",
                      readonly:
                        (_vm.readonly && _vm.isFieldReadonlyAfterApproval) ||
                        _vm.isTripApprovedAndSubmittedByRequester,
                      "data-cy": "trip-num-vehicles",
                    },
                    model: {
                      value: _vm.tripRequest.numVehicles,
                      callback: function ($$v) {
                        _vm.$set(_vm.tripRequest, "numVehicles", _vm._n($$v))
                      },
                      expression: "tripRequest.numVehicles",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tripRequest.needDistrictVehicles &&
                        (_vm.tripRequest.vehicleType ==
                          _vm.VEHICLE_TYPES.APPROVED_CHARTER ||
                          _vm.tripRequest.vehicleType ==
                            _vm.VEHICLE_TYPES.CONTRACTOR) &&
                        (_vm.tripRequest.status != 0 ||
                          _vm.tripRequestConfig.display.contractors),
                      expression:
                        "\n          tripRequest.needDistrictVehicles &&\n          (tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER ||\n            tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR) &&\n          (tripRequest.status != 0 || tripRequestConfig.display.contractors)\n        ",
                    },
                  ],
                  attrs: { cols: "12", md: "4" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Email:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Phone:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Contact:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Address:")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "9" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.selectedAddTrans.email))]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.selectedAddTrans.phone))]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.selectedAddTrans.contactName))]
                        ),
                        _c("div", {
                          staticClass: "text-subtitle-2 font-weight-normal",
                          domProps: {
                            innerHTML: _vm._s(_vm.selectedAddTrans.addressText),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tripRequest.needDistrictVehicles &&
                        _vm.tripRequest.vehicleTypeId == -1,
                      expression:
                        "tripRequest.needDistrictVehicles && tripRequest.vehicleTypeId == -1",
                    },
                  ],
                  attrs: { cols: "12", md: "3" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "4" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Email:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Address:")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.selectedAddTrans.email))]
                        ),
                        _c("div", {
                          staticClass: "text-subtitle-2 font-weight-normal",
                          domProps: {
                            innerHTML: _vm._s(_vm.selectedAddTrans.addressText),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tripRequest.needDistrictVehicles,
                  expression: "tripRequest.needDistrictVehicles",
                },
              ],
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.showSplitTripOption
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                disabled: !_vm.disableSplitTripOption,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-checkbox",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !!_vm.disableSplitTripOption,
                                                      label:
                                                        "Split Trip (2 pickup & return dates/times)",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tripRequest
                                                          .splitTrip,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.tripRequest,
                                                          "splitTrip",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "tripRequest.splitTrip",
                                                    },
                                                  },
                                                  "v-checkbox",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                351437125
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Trips with created Assignments cannot be split"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tripRequestConfig.display.dropoffOnly &&
                  !_vm.tripRequest.splitTrip
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Dropoff Only",
                              readonly:
                                _vm.readonly &&
                                !_vm.tripRequest.permissions.canEditSiteAdmin,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDOPU("do")
                              },
                            },
                            model: {
                              value: _vm.tripRequest.dropoffOnly,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "dropoffOnly", $$v)
                              },
                              expression: "tripRequest.dropoffOnly",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tripRequestConfig.display.pickupOnly &&
                  !_vm.tripRequest.splitTrip
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Pickup Only",
                              readonly:
                                _vm.readonly &&
                                !_vm.tripRequest.permissions.canEditSiteAdmin,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleDOPU("pu")
                              },
                            },
                            model: {
                              value: _vm.tripRequest.pickupOnly,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "pickupOnly", $$v)
                              },
                              expression: "tripRequest.pickupOnly",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.tripRequest.dropoffOnly || _vm.tripRequest.pickupOnly) &&
                  !_vm.tripRequest.splitTrip
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Location",
                              readonly:
                                _vm.readonly &&
                                !_vm.tripRequest.permissions.canEditSiteAdmin,
                            },
                            model: {
                              value: _vm.tripRequest.vehLocation,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "vehLocation", $$v)
                              },
                              expression: "tripRequest.vehLocation",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.tripRequest.splitTrip
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-title", [_vm._v("Drop-Off Leg")]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c("v-card-title", [
                                                _vm._v("Vehicle Pickup"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: 12 } },
                                                    [
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("date-picker", {
                                                        ref: "dropOffLegVehPickupDate",
                                                        attrs: {
                                                          label: "Date",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .dropOffLegVehPickupDate ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .dropOffLegVehPickupDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "dropOffLegVehPickupDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.dropOffLegVehPickupDate",
                                                        },
                                                      }),
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("time-picker", {
                                                        ref: "dropOffLegVehPickupTime",
                                                        attrs: {
                                                          label: "Time",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .dropOffLegVehPickupTime ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .dropOffLegVehPickupTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "dropOffLegVehPickupTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.dropOffLegVehPickupTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c("v-card-title", [
                                                _vm._v("Vehicle Return"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: 12 } },
                                                    [
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("date-picker", {
                                                        ref: "dropOffLegVehReturnDate",
                                                        attrs: {
                                                          label: "Date",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .dropOffLegVehReturnDate ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .dropOffLegVehReturnDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "dropOffLegVehReturnDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.dropOffLegVehReturnDate",
                                                        },
                                                      }),
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("time-picker", {
                                                        ref: "dropOffLegVehReturnTime",
                                                        attrs: {
                                                          label: "Time",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .dropOffLegVehReturnTime ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .dropOffLegVehReturnTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "dropOffLegVehReturnTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.dropOffLegVehReturnTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-title", [_vm._v("Return Leg")]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c("v-card-title", [
                                                _vm._v("Vehicle Pickup"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: 12 } },
                                                    [
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("date-picker", {
                                                        ref: "returnLegVehPickupDate",
                                                        attrs: {
                                                          label: "Date",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .returnLegVehPickupDate ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .returnLegVehPickupDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "returnLegVehPickupDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.returnLegVehPickupDate",
                                                        },
                                                      }),
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("time-picker", {
                                                        ref: "returnLegVehPickupTime",
                                                        attrs: {
                                                          label: "Time",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .returnLegVehPickupTime ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .returnLegVehPickupTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "returnLegVehPickupTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.returnLegVehPickupTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c("v-card-title", [
                                                _vm._v("Vehicle Return"),
                                              ]),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: 12 } },
                                                    [
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("date-picker", {
                                                        ref: "returnLegVehReturnDate",
                                                        attrs: {
                                                          label: "Date",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .returnLegVehReturnDate ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .returnLegVehReturnDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "returnLegVehReturnDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.returnLegVehReturnDate",
                                                        },
                                                      }),
                                                      !_vm.tripRequest.status ||
                                                      _vm.tripRequest.status ==
                                                        -1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "red--text",
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  "*Required"
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("time-picker", {
                                                        ref: "returnLegVehReturnTime",
                                                        attrs: {
                                                          label: "Time",
                                                          readonly:
                                                            _vm.readonly &&
                                                            !_vm.tripRequest
                                                              .permissions
                                                              .canEditSiteAdmin &&
                                                            !_vm.canEditDates &&
                                                            !_vm
                                                              .tripRequestConfig
                                                              .display
                                                              .nonAdminEditVehicleDateTime,
                                                          rules: [
                                                            () =>
                                                              !!_vm.tripRequest
                                                                .returnLegVehReturnTime ||
                                                              "This field is required",
                                                          ],
                                                          required: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.tripRequest
                                                              .returnLegVehReturnTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.tripRequest,
                                                              "returnLegVehReturnTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tripRequest.returnLegVehReturnTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  !_vm.tripRequest.splitTrip
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            cols: _vm.isMobile
                              ? 12
                              : _vm.showVenueDateTimes > 0
                              ? 3
                              : 6,
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-title", [_vm._v("Vehicle Pick-up")]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.isMobile
                                          ? 12
                                          : _vm.showVenueDateTimes > 0
                                          ? 12
                                          : 6,
                                      },
                                    },
                                    [
                                      !_vm.tripRequest.status ||
                                      _vm.tripRequest.status == -1
                                        ? _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [_c("small", [_vm._v("*Required")])]
                                          )
                                        : _vm._e(),
                                      _c("date-picker", {
                                        ref: "vehPickupDate",
                                        attrs: {
                                          label: "Date",
                                          readonly:
                                            _vm.readonly &&
                                            !_vm.tripRequest.permissions
                                              .canEditSiteAdmin &&
                                            !_vm.canEditDates &&
                                            !_vm.tripRequestConfig.display
                                              .nonAdminEditVehicleDateTime,
                                          rules: [
                                            () =>
                                              !!_vm.tripRequest.vehPickupDate ||
                                              "This field is required",
                                          ],
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.tripRequest.vehPickupDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tripRequest,
                                              "vehPickupDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tripRequest.vehPickupDate",
                                        },
                                      }),
                                      !_vm.tripRequest.status ||
                                      _vm.tripRequest.status == -1
                                        ? _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [_c("small", [_vm._v("*Required")])]
                                          )
                                        : _vm._e(),
                                      _c("time-picker", {
                                        ref: "vehPickupTime",
                                        attrs: {
                                          label: "Time",
                                          readonly:
                                            _vm.readonly &&
                                            !_vm.tripRequest.permissions
                                              .canEditSiteAdmin &&
                                            !_vm.canEditDates &&
                                            !_vm.tripRequestConfig.display
                                              .nonAdminEditVehicleDateTime,
                                          rules: [
                                            () =>
                                              !!_vm.tripRequest.vehPickupTime ||
                                              "This field is required",
                                          ],
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.tripRequest.vehPickupTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tripRequest,
                                              "vehPickupTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tripRequest.vehPickupTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showVenueDateTimes > 0
                    ? [
                        _vm.tripRequest.splitTrip ? _c("v-spacer") : _vm._e(),
                        _vm.showBothCols || _vm.showDropoffCol
                          ? _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: _vm.isMobile
                                    ? 12
                                    : _vm.showVenueDateTimes > 0
                                    ? 3
                                    : 6,
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { outlined: "" } },
                                  [
                                    _c("v-card-title", [
                                      _vm._v("Arrive at Venue (Info Only)"),
                                    ]),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: _vm.isMobile
                                                ? 12
                                                : _vm.showVenueDateTimes > 0
                                                ? 12
                                                : 6,
                                            },
                                          },
                                          [
                                            !_vm.tripRequest.status ||
                                            _vm.tripRequest.status == -1
                                              ? _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [
                                                    _c("small", [
                                                      _vm._v("*Required"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("date-picker", {
                                              ref: "venueArriveDate",
                                              attrs: {
                                                label: "Date",
                                                min: _vm.minArriveDepartDate,
                                                max: _vm.maxArriveDepartDate,
                                                readonly:
                                                  _vm.readonly &&
                                                  !_vm.tripRequest.permissions
                                                    .canEditSiteAdmin &&
                                                  !_vm.canEditDates &&
                                                  !_vm.tripRequestConfig.display
                                                    .nonAdminEditVehicleDateTime,
                                                rules: [
                                                  () =>
                                                    !!_vm.tripRequest
                                                      .venueArriveDate ||
                                                    "This field is required",
                                                ],
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.tripRequest
                                                    .venueArriveDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tripRequest,
                                                    "venueArriveDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tripRequest.venueArriveDate",
                                              },
                                            }),
                                            !_vm.tripRequest.status ||
                                            _vm.tripRequest.status == -1
                                              ? _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [
                                                    _c("small", [
                                                      _vm._v("*Required"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("time-picker", {
                                              ref: "venueArriveTime",
                                              attrs: {
                                                label: "Time",
                                                readonly:
                                                  _vm.readonly &&
                                                  !_vm.tripRequest.permissions
                                                    .canEditSiteAdmin &&
                                                  !_vm.canEditDates &&
                                                  !_vm.tripRequestConfig.display
                                                    .nonAdminEditVehicleDateTime,
                                                rules: [
                                                  () =>
                                                    !!_vm.tripRequest
                                                      .venueArriveTime ||
                                                    "This field is required",
                                                ],
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.tripRequest
                                                    .venueArriveTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tripRequest,
                                                    "venueArriveTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tripRequest.venueArriveTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showBothCols || _vm.showPickupCol
                          ? _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: _vm.isMobile
                                    ? 12
                                    : _vm.showVenueDateTimes > 0
                                    ? 3
                                    : 6,
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { outlined: "" } },
                                  [
                                    _c("v-card-title", [
                                      _vm._v("Depart Venue (Info Only)"),
                                    ]),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: _vm.isMobile
                                                ? 12
                                                : _vm.showVenueDateTimes > 0
                                                ? 12
                                                : 6,
                                            },
                                          },
                                          [
                                            !_vm.tripRequest.status ||
                                            _vm.tripRequest.status == -1
                                              ? _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [
                                                    _c("small", [
                                                      _vm._v("*Required"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("date-picker", {
                                              ref: "venueDepartDate",
                                              attrs: {
                                                label: "Date",
                                                min: _vm.minArriveDepartDate,
                                                max: _vm.maxArriveDepartDate,
                                                readonly:
                                                  _vm.readonly &&
                                                  !_vm.tripRequest.permissions
                                                    .canEditSiteAdmin &&
                                                  !_vm.canEditDates &&
                                                  !_vm.tripRequestConfig.display
                                                    .nonAdminEditVehicleDateTime,
                                                rules: [
                                                  () =>
                                                    !!_vm.tripRequest
                                                      .venueDepartDate ||
                                                    "This field is required",
                                                ],
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.tripRequest
                                                    .venueDepartDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tripRequest,
                                                    "venueDepartDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tripRequest.venueDepartDate",
                                              },
                                            }),
                                            !_vm.tripRequest.status ||
                                            _vm.tripRequest.status == -1
                                              ? _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [
                                                    _c("small", [
                                                      _vm._v("*Required"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("time-picker", {
                                              ref: "venueDepartTime",
                                              attrs: {
                                                label: "Time",
                                                readonly:
                                                  _vm.readonly &&
                                                  !_vm.tripRequest.permissions
                                                    .canEditSiteAdmin &&
                                                  !_vm.canEditDates &&
                                                  !_vm.tripRequestConfig.display
                                                    .nonAdminEditVehicleDateTime,
                                                rules: [
                                                  () =>
                                                    !!_vm.tripRequest
                                                      .venueDepartTime ||
                                                    "This field is required",
                                                ],
                                                required: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.tripRequest
                                                    .venueDepartTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tripRequest,
                                                    "venueDepartTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tripRequest.venueDepartTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.tripRequest.splitTrip ? _c("v-spacer") : _vm._e(),
                      ]
                    : _vm._e(),
                  !_vm.tripRequest.splitTrip
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            cols: _vm.isMobile
                              ? 12
                              : _vm.showVenueDateTimes > 0
                              ? 3
                              : 6,
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-title", [_vm._v("Vehicle Return")]),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: _vm.isMobile
                                          ? 12
                                          : _vm.showVenueDateTimes > 0
                                          ? 12
                                          : 6,
                                      },
                                    },
                                    [
                                      !_vm.tripRequest.status ||
                                      _vm.tripRequest.status == -1
                                        ? _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [_c("small", [_vm._v("*Required")])]
                                          )
                                        : _vm._e(),
                                      _c("date-picker", {
                                        ref: "vehReturnDate",
                                        attrs: {
                                          label: "Date",
                                          readonly:
                                            _vm.readonly &&
                                            !_vm.tripRequest.permissions
                                              .canEditSiteAdmin &&
                                            !_vm.canEditDates &&
                                            !_vm.tripRequestConfig.display
                                              .nonAdminEditVehicleDateTime,
                                          rules: [
                                            () =>
                                              !!_vm.tripRequest.vehReturnDate ||
                                              "This field is required",
                                          ],
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.tripRequest.vehReturnDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tripRequest,
                                              "vehReturnDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tripRequest.vehReturnDate",
                                        },
                                      }),
                                      !_vm.tripRequest.status ||
                                      _vm.tripRequest.status == -1
                                        ? _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [_c("small", [_vm._v("*Required")])]
                                          )
                                        : _vm._e(),
                                      _c("time-picker", {
                                        ref: "vehReturnTime",
                                        attrs: {
                                          label: "Time",
                                          readonly:
                                            _vm.readonly &&
                                            !_vm.tripRequest.permissions
                                              .canEditSiteAdmin &&
                                            !_vm.canEditDates &&
                                            !_vm.tripRequestConfig.display
                                              .nonAdminEditVehicleDateTime,
                                          rules: [
                                            () =>
                                              !!_vm.tripRequest.vehReturnTime ||
                                              "This field is required",
                                          ],
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.tripRequest.vehReturnTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tripRequest,
                                              "vehReturnTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tripRequest.vehReturnTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.tripRequest.splitTrip
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "font-weight-medium" }, [
                          _vm._v(" Drop-Off Leg Hours"),
                          _c("span", { staticClass: "pl-2" }, [
                            _vm._v(
                              _vm._s(_vm.tripRequest.dropOffTotalHours || "0")
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "font-weight-medium" }, [
                          _vm._v(" Total Trip Hours"),
                          _c("span", { staticClass: "pl-2" }, [
                            _vm._v(
                              _vm._s(_vm.tripRequest.totalTripHours || "0")
                            ),
                          ]),
                        ]),
                      ]),
                      _c("v-col", { attrs: { cols: "6" } }, [
                        _c("div", { staticClass: "font-weight-medium" }, [
                          _vm._v(" Return Leg Hours"),
                          _c("span", { staticClass: "pl-2" }, [
                            _vm._v(
                              _vm._s(_vm.tripRequest.returnTotalHours || "0")
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.activeMessages, function (am) {
                return _c(
                  "v-row",
                  { key: am.id, attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              type: am.alertType || "info",
                              text: "",
                            },
                          },
                          [_vm._v(_vm._s(am.message))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm.tripRequestConfig.display.specialNeedsVehicle
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label:
                                _vm.tripRequestConfig.labels
                                  .needSpecialNeedsVehicles,
                              readonly: _vm.cannotModifyFields,
                            },
                            model: {
                              value: _vm.tripRequest.needSpecialNeedsVehicle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tripRequest,
                                  "needSpecialNeedsVehicle",
                                  $$v
                                )
                              },
                              expression: "tripRequest.needSpecialNeedsVehicle",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.tripRequestConfig.display.numSpecialNeedsVehicle &&
                      _vm.tripRequest.needSpecialNeedsVehicle
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    _vm.tripRequestConfig.labels
                                      .numSpecialNeedsVehicles,
                                  readonly: _vm.cannotModifyFields,
                                  min: "0",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.tripRequest.numSpecialNeedsVehicle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripRequest,
                                      "numSpecialNeedsVehicle",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "tripRequest.numSpecialNeedsVehicle",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tripRequestConfig.labels.numWheelchairSlots
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    _vm.tripRequestConfig.labels
                                      .numWheelchairSlots,
                                  readonly: _vm.cannotModifyFields,
                                  min: "0",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.tripRequest.numWheelchairSlot,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripRequest,
                                      "numWheelchairSlot",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "tripRequest.numWheelchairSlot",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tripRequestConfig.labels.numSafetyVests
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    _vm.tripRequestConfig.labels.numSafetyVests,
                                  readonly: _vm.cannotModifyFields,
                                  min: "0",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.tripRequest.numSafetyVest,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripRequest,
                                      "numSafetyVest",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "tripRequest.numSafetyVest",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tripRequestConfig.labels.numFoldDownSeats
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "2" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label:
                                    _vm.tripRequestConfig.labels
                                      .numFoldDownSeats,
                                  readonly: _vm.cannotModifyFields,
                                  min: "0",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.tripRequest.numFoldDownSeat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripRequest,
                                      "numFoldDownSeat",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "tripRequest.numFoldDownSeat",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12", md: "12" } },
                    [_c("h3", [_vm._v("Special Travel Needs:")])]
                  ),
                  _vm._l(_vm.specialTravelNeeds, function (stn, index) {
                    return _c(
                      "v-col",
                      { key: index, attrs: { cols: "12", md: "3" } },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            value: stn.id,
                            label: stn.name,
                            multiple: "",
                            readonly:
                              (_vm.readonly &&
                                _vm.isFieldReadonlyAfterApproval) ||
                              _vm.isTripApprovedAndSubmittedByRequester,
                          },
                          model: {
                            value: _vm.tripRequest.specialTravelNeeds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tripRequest,
                                "specialTravelNeeds",
                                $$v
                              )
                            },
                            expression: "tripRequest.specialTravelNeeds",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.tripRequestConfig.display.commentsConcerningVehicle
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label:
                                _vm.tripRequestConfig.labels.vehicleComments,
                              dense: "",
                              outlined: "",
                              rows: "3",
                              readonly:
                                _vm.readonly &&
                                !_vm.tripRequest.permissions.canEditSiteAdmin,
                            },
                            model: {
                              value: _vm.tripRequest.vehComments,
                              callback: function ($$v) {
                                _vm.$set(_vm.tripRequest, "vehComments", $$v)
                              },
                              expression: "tripRequest.vehComments",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _vm.tripRequestConfig.display.comments
                        ? _c("span", { staticClass: "red--text" }, [
                            _c("small", [_vm._v("*Required")]),
                          ])
                        : _vm._e(),
                      _c("v-textarea", {
                        ref: "comments",
                        attrs: {
                          hint: _vm.tripRequestConfig.messages.comments,
                          readonly: _vm.readonly,
                          rules: [
                            () =>
                              (_vm.tripRequestConfig.display.comments
                                ? !!_vm.tripRequest.comments
                                : true) || "This field is required",
                          ],
                          "auto-grow": "",
                          counter: "400",
                          label: "Comments",
                          maxlength: "400",
                          outlined: "",
                          "persistent-hint": "",
                          required: "",
                          rows: "3",
                        },
                        model: {
                          value: _vm.tripRequest.comments,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripRequest, "comments", $$v)
                          },
                          expression: "tripRequest.comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm.customFormFields
            ? _c(
                "v-row",
                _vm._l(_vm.customFormFields, function (cff, i) {
                  return _c("custom-form-field", {
                    key: i,
                    ref: cff.id,
                    refInFor: true,
                    attrs: {
                      cff: cff,
                      value: _vm.tripRequest.customFormFields[cff.id],
                      readonly: _vm.readonly,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.$emit("handleCFFInput", {
                          cffId: cff.id,
                          value: $event,
                        })
                      },
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }