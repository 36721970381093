<template>
  <v-container fluid>
    <v-form class="full-width">
      <div>
        <template v-if="showFundingSources">
          <v-row>
            <v-col cols="12" md="12">
              <v-btn color="primary" @click="addFundingSource" :disabled="addFSDisabled">
                <v-icon>mdi-plus</v-icon>
                {{ fundingSourceLabel }}
              </v-btn>
            </v-col>

            <v-subheader v-if="fsRequired">A Funding Source is required for the selected Trip Type</v-subheader>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12" md="12">
              <v-card v-for="(item, index) in tripRequest.fundingSources" :key="item.id ?? index" class="mb-4">
                <v-card-text>
                  <v-container>
                    <FundingSourceWithBudgetCode
                      :fsLabel="`${fundingSourceLabel} #${index + 1}`"
                      :disabled="tripRequest.fundingSources[index].delete"
                      :readonly="cannotModifyFields"
                      :hint="
                        fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId]
                          ? fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].description
                          : ''
                      "
                      :fundingSourceIndex="index"
                      @input="setTripFunding({ val: $event, index })"
                    />

                    <v-row dense>
                      <v-col cols="12" md="4">
                        <div
                          v-if="showApproverInfo(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])"
                          class="text-subtitle-2 font-weight-bold"
                        >
                          Funding Approver:
                        </div>

                        <template
                          v-if="
                            showAllotmentBalance(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])
                          "
                        >
                          <div class="text-subtitle-2 font-weight-bold">Est Trip Cost:</div>
                          <div class="text-subtitle-2 font-weight-bold">Est Balance:</div>
                        </template>
                      </v-col>

                      <v-col cols="12" md="8">
                        <div
                          v-if="showApproverInfo(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])"
                          class="text-subtitle-2 font-weight-normal"
                        >
                          {{ fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].approverUsername }}
                          ({{ fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].approverEmail }})
                        </div>

                        <template
                          v-if="
                            showAllotmentBalance(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId])
                          "
                        >
                          <div class="text-subtitle-2 font-weight-normal">{{ toMoney(estTripCost) }}</div>
                          <div class="text-subtitle-2 font-weight-normal">
                            {{
                              toMoney(
                                estimateBalance(
                                  fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId] &&
                                    fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget
                                    ? fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget
                                        .allotment
                                    : 0
                                )
                              )
                            }}
                          </div>
                        </template>
                      </v-col>

                      <v-col
                        cols="12"
                        md="12"
                        v-if="
                          showAllotmentBalance(fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId]) &&
                          estimateBalance(
                            fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId] &&
                              fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget
                              ? fundingSourcesById[tripRequest.fundingSources[index].fundingSourceId].budget.allotment
                              : 0
                          ) < 0
                        "
                      >
                        <v-alert outlined type="error" text>{{ tripRequestConfig.messages.allotmentDepleted }}</v-alert>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    :disabled="cannotModifyFields || fsRequired"
                    @click="deleteFundingSource(item, index)"
                    color="error"
                  >
                    <v-icon left>
                      {{ item.delete ? 'mdi-refresh' : 'mdi-delete' }}
                    </v-icon>
                    {{ item.delete ? 'Undo' : 'Delete' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-if="showThirdPartyOptions">
          <v-row class="mt-4">
            <v-col cols="12" md="10">{{ tripRequestConfig.labels.thirdPartyPayment }}</v-col>

            <v-col cols="12" md="2">
              <div class="d-flex align-center justify-center">
                <span>No</span>
                <v-switch
                  class="ml-4"
                  v-model.number="tripRequest.payableToThirdParty"
                  :true-value="1"
                  :false-value="0"
                  inset
                  :readonly="cannotModifyFields"
                  @change="handleThirdParty"
                ></v-switch>
                <span>Yes</span>
              </div>
            </v-col>
          </v-row>

          <template v-if="tripRequest.payableToThirdParty == 1">
            <v-row dense class="ml-1">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="tripRequest.thirdPartyAmount"
                  label="Amount"
                  type="number"
                  min="0"
                  prepend-icon="mdi-currency-usd"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <template v-slot:label>
                  <div>Payment Option</div>
                </template>

                <v-radio-group v-model="tripRequest.thirdPartyOption" :readonly="cannotModifyFields">
                  <v-radio
                    v-for="option in filteredPayments"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="6">
                <v-subheader>Payment Due To</v-subheader>

                <v-row dense>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="tripRequest.thirdPName"
                      label="Name"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="tripRequest.thirdPAddress"
                      label="Address"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="tripRequest.thirdPCity"
                      label="City"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="tripRequest.thirdPState"
                      label="State"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="tripRequest.thirdPZip"
                      label="Zip"
                      outlined
                      :readonly="cannotModifyFields"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense class="ml-1" v-if="tripRequest.thirdPartyOption == 2">
              <v-col cols="12" md="6">
                <v-text-field
                  label="P-Card Account Number"
                  v-model="tripRequest.pcardAccount"
                  outlined
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="ml-1">
              <v-col cols="12" md="6">
                <v-text-field
                  label="Purchase Order / Requisition Number"
                  v-model="tripRequest.thirdPartyPO"
                  outlined
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="ml-1">
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="tripRequest.thirdPartyComments"
                  label="Comments Concerning Payment"
                  dense
                  outlined
                  rows="3"
                  :readonly="cannotModifyFields"
                ></v-textarea>
              </v-col>
            </v-row>
          </template>
        </template>

        <v-row v-if="customFormFields">
          <custom-form-field
            v-for="(cff, i) in customFormFields"
            :ref="cff.id"
            :key="i"
            :cff="cff"
            :value="tripRequest.customFormFields[cff.id]"
            :readonly="cannotModifyFields"
            @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
          ></custom-form-field>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { toMoney, SINGLE_BUDGET_CODE_IDS } from '@/util';
import { removedBudgetCodeMessage } from '@/util/messages';

import { TRIP_STATUS } from '@/shared/common';

import { GET_BUDGET_CODES } from '@/store/modules/BudgetCode/actions';

import CustomFormField from './CustomFormField.vue';

import FundingSourceWithBudgetCode from '@/components/FundingSource/FundingSourceWithBudgetCode.vue';

export default {
  name: 'FundingSource',
  inject: ['eventHub', 'showFunding'],
  components: { CustomFormField, FundingSourceWithBudgetCode },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    customFormFields: Array,
    step: Number,
    readonly: Boolean,
  },
  data() {
    return {
      budgetCodes: [],
      filteredBudgetCodes: [],
      locations: [],
      codeStrs: [],
      SINGLE_BUDGET_CODE_IDS: SINGLE_BUDGET_CODE_IDS,
      removedBudgetCodeMessage,
    };
  },
  computed: {
    ...mapGetters('fundingSource', ['fundingSources', 'filterFundingSources', 'fundingSourcesById']),
    ...mapGetters('user', ['me']),
    ...mapGetters('location', ['schools', 'locationsById']),
    ...mapGetters('thirdPartyPayment', ['thirdPartyPayments']),
    ...mapGetters('budgetCode', ['budgetCodesByLocation']),
    ...mapGetters('config', ['budgetCodeConfig']),
    ...mapGetters('tripRequest', [
      'currentTripLocation',
      'currentTripRequest',
      'currentTripRequestApprovalStatus',
      'isCurrentTripFundingNoBudgetCode',
      'isTripApprovedAndSubmittedByRequester',
    ]),
    fundingSourceLabel() {
      return this.tripRequestConfig.labels?.addFundingSource || 'Add Funding Source';
    },
    cannotModifyFields() {
      return !this.tripRequest.fieldPermissions?.fundingSourceSection?.canEdit;
    },
    fsRequired() {
      const requesterRequiresFundingSource =
        this.tripRequestConfig?.display?.showFunding &&
        (this.tripRequestConfig?.display?.requesterFundingSourceRequired || []).includes(this.tripRequest.tripTypeId);

      const approverRequiresFundingSource =
        this.me.is.approver &&
        this.tripRequest.status !== TRIP_STATUS.DRAFT &&
        (this.tripRequestConfig?.display?.approverFundingSourceRequired || []).includes(this.tripRequest.tripTypeId);

      return approverRequiresFundingSource || requesterRequiresFundingSource;
    },
    addFSDisabled() {
      const fundingSourceLimit = this.tripRequestConfig.display.fundingSourceLimit || 1;

      if (this.tripRequest.fundingSources.length >= fundingSourceLimit || this.cannotModifyFields) {
        return true;
      }

      return false;
    },
    estTripCost() {
      return 123.45;
    },
    showFundingSources() {
      return (
        this.showFunding ||
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.tripRequestConfig.display.showFunding ||
        this.fsRequired
      );
    },
    filteredPayments() {
      return this.thirdPartyPayments.filter((option) => {
        return option.id !== 2 || (option.id === 2 && this.tripRequestConfig.display.pcardOption);
      });
    },
    showThirdPartyOptions() {
      if (this.tripRequestConfig.display.thirdPartyPayment)
        return this.tripRequestConfig.display?.thirdPartyPaymentTripTypes?.includes(this.tripRequest.tripTypeId);
      return false;
    },
  },
  created() {
    this.eventHub.$on('validateStepFundingSource', (reset) => this.validate(reset));
    if (this.tripRequest.tripTypeId) this.handleExistingFS();
    else this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepFundingSource');
  },
  methods: {
    ...mapActions('fundingSource', ['saveFundingSource']),
    ...mapActions('budgetCode', [GET_BUDGET_CODES]),
    ...mapActions('thirdPartyPayment', ['getThirdPartyPayments']),
    ...mapActions('tripRequest', ['updateTripRequest']),
    toMoney,
    getBudgetCodeName(budgetCodeId) {
      return this.budgetCodes.find((bc) => bc.id === budgetCodeId)?.name;
    },
    isTripFundingSourceValid({ fundingSourceId }) {
      const allowedLocationIds = this.fundingSourcesById[fundingSourceId]?.locations;

      if (!allowedLocationIds || allowedLocationIds?.length === 0) return true;

      return allowedLocationIds.includes(this.currentTripRequest?.locationId);
    },
    setTripFunding({ val, index }) {
      this.tripRequest.fundingSources[index].budgetCode = val.budgetCode;
      this.tripRequest.fundingSources[index].budgetCodeId = val.budgetCodeId;
      this.tripRequest.fundingSources[index].fundingSourceId = val.fundingSourceId;
    },
    async fetchItems() {
      this.locations = this.schools;
      await this.getThirdPartyPayments();
    },
    filteredFundingSources(selected) {
      return this.filterFundingSources({
        tripTypeId: this.tripRequest.tripTypeId,
        locationId: this.tripRequest.locationId,
        existingFundingSourceIds: new Set(this.tripRequest.fundingSources.map((fs) => fs.fundingSourceId)),
        selected,
      });
    },
    addFundingSource() {
      if (!this.tripRequest.fundingSources) this.tripRequest.fundingSources = [];
      this.tripRequest.fundingSources.push({
        fundingSourceId: 0,
        budgetCode: '',
      });

      // Focus on last item in funding source
      this.$nextTick(() => {
        const lastIndex = this.tripRequest.fundingSources.length - 1;
        const lastRef = this.$refs['fs' + lastIndex][0];

        if (lastRef && lastRef.$el) {
          lastRef.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });

          this.$nextTick(() => {
            const inputElement = lastRef.$el.querySelector('input');
            if (inputElement) {
              inputElement.focus();
            }
          });
        }
      });
    },
    deleteFundingSource(item, index) {
      if (item.id) {
        item.delete = !item.delete;
        this.tripRequest.fundingSources.push({});
        this.tripRequest.fundingSources.pop();
      } else this.tripRequest.fundingSources.splice(index, 1);
    },
    async handleExistingFS() {
      await this.fetchItems();
    },
    showApproverInfo(fs) {
      if (fs && fs.approverId) return true;
      else return false;
    },
    showAllotmentBalance(fs) {
      return false; // temporary
      // if (fs && fs.allotmentsUsed) return true;
      // else return false;
    },
    estimateBalance(currBalance) {
      return currBalance - this.estTripCost;
    },
    handleThirdParty(selected) {
      if (selected) {
        setTimeout(() => {
          const div = document.getElementById('form-content');
          div.scrollTop = div.scrollHeight;
        }, 100);
      }
    },
    validate(reset) {
      if (!reset) {
        this.tripRequest.fundingSources.forEach((e, i) => this.$refs['fs' + i][0].validate(true));

        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation();
          });
      } else {
        this.tripRequest.fundingSources.forEach((e, i) => this.$refs['fs' + i][0].resetValidation());

        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation(true);
          });
      }
    },
  },
  watch: {
    async tripRequest(value) {
      if (value.tripTypeId) this.handleExistingFS();
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
