var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-text",
        [
          _vm.leg > 0
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", align: "center" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: _vm.leg == 1 ? "green" : "blue" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.leg == 1
                                  ? "mdi-arrow-right-bold-circle"
                                  : "mdi-arrow-left-bold-circle"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                          attrs: { color: "yellow" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.leg == 1
                                  ? "Split Trip Drop-Off Leg"
                                  : "Split Trip Return Leg"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Trip #")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(" " + _vm._s(_vm.tripRequest.id) + " ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Vehicle Type")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tripRequest.vehicleType ===
                                    _vm.VEHICLE_TYPES.NORMAL
                                    ? _vm.vehicleTypesById[
                                        _vm.tripRequest.vehicleTypeId
                                      ].name
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Pickup")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.pickupText))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("From")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.locationsById[_vm.tripRequest.locationId]
                                    .name
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Total Students")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.tripRequest.totalStudents) + " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Total Adults")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.tripRequest.totalAdults) + " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Trip Year")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(
                                    _vm.tripRequest.leaveDate
                                  ).getFullYear()
                                ) +
                                " - " +
                                _vm._s(
                                  _vm.getWeek(
                                    new Date(_vm.tripRequest.leaveDate)
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Trip Type")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tripTypesById[_vm.tripRequest.tripTypeId]
                                    .name
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Out of County?")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tripRequest.outOfCounty ? "Yes" : "No"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Return")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.returnText))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Destination")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.destinationsById[
                                    _vm.tripRequest.destinationId
                                  ]
                                    ? _vm.destinationsById[
                                        _vm.tripRequest.destinationId
                                      ].name
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("# Drivers needed")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.tripRequest.numVehicles))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("# Special Needs students")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tripRequest.specialNeedsStudents || 0
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Trip Zone")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.tripZone))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Trip Event(s)")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tripRequest.tripEventIds
                                    .map((e) => _vm.tripEventsById[e].name)
                                    .join(", ")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("Do you need a wheelchair lift?")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.tripRequest.needSpecialNeedsVehicle
                                    ? "Yes"
                                    : "No"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.tripRequestConfig.labels
                                  .numSpecialNeedsVehicles
                              )
                            ),
                          ]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [
                            _vm._v(
                              _vm._s(_vm.tripRequest.numSpecialNeedsVehicle)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.tripRequestConfig.labels.numWheelchairSlots
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tripRequestConfig.labels
                                      .numWheelchairSlots
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.tripRequest.numWheelchairSlot)
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tripRequestConfig.labels.numSafetyVests
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tripRequestConfig.labels.numSafetyVests
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.tripRequest.numSafetyVest))]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tripRequestConfig.labels.numFoldDownSeats
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-subtitle-2 font-weight-bold",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.tripRequestConfig.labels
                                      .numFoldDownSeats
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-subtitle-2 font-weight-normal",
                              },
                              [_vm._v(_vm._s(_vm.tripRequest.numFoldDownSeat))]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-bold" },
                          [_vm._v("# Vehicles reserved")]
                        ),
                      ]),
                      _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          { staticClass: "text-subtitle-2 font-weight-normal" },
                          [_vm._v(_vm._s(_vm.vehiclesReserved))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }