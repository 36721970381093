<template>
  <v-container fluid>
    <v-form class="full-width">
      <div>
        <v-row>
          <v-col cols="12" md="12">
            <v-alert outlined type="info" text>
              <div v-html="generalMessage"></div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              ref="category"
              label="Category"
              v-model="tripRequest.category"
              :items="categories"
              item-text="name"
              item-value="id"
              :rules="[() => !!tripRequest.category || 'This field is required']"
              required
              outlined
              @change="handleTripCategorySelect"
              :readonly="readonly"
              data-cy="trip-category"
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              ref="tripTypeId"
              v-model="tripRequest.tripTypeId"
              :items="filteredTripTypes"
              item-text="name"
              item-value="id"
              label="Trip Type"
              :rules="[() => !!tripRequest.tripTypeId || 'This field is required']"
              required
              outlined
              :disabled="!tripRequest.category"
              @change="handleTripTypeSelect"
              :readonly="readonly"
              data-cy="trip-type"
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              ref="tripEventIds"
              v-model="tripRequest.tripEventIds"
              :items="filteredTripEvents"
              item-text="name"
              item-value="id"
              label="Event"
              multiple
              v-on:input="limiter"
              :rules="[() => tripRequest.tripEventIds.length > 0 || 'This field is required']"
              required
              outlined
              :disabled="!tripRequest.tripTypeId"
              @change="handleTripEventSelect"
              :readonly="(readonly && isFieldReadonlyAfterApproval) || isTripApprovedAndSubmittedByRequester"
              data-cy="trip-event"
            >
              <template
                v-slot:item="{ item }"
                v-if="tripRequest.tripTypeId && tripTypesById[tripRequest.tripTypeId].allowEventRates"
              >
                {{ item.name }}
              </template>
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              ref="locationId"
              v-model="tripRequest.locationId"
              :items="locations"
              :item-text="getLocationText"
              item-value="id"
              label="Your School/Dept"
              :rules="[() => !!tripRequest.locationId || 'This field is required']"
              required
              outlined
              @change="handleLocationSelect"
              :readonly="
                readonly ||
                (Object.keys(tripRequest.permissions).length &&
                  (!tripRequest.permissions.canEditPreApproval ||
                    !tripRequest.permissions.canEditMidApproval ||
                    !tripRequest.permissions.canEditApproved)) ||
                (usesRoster && Number.isInteger(tripRequest.locationId) && !step)
              "
              data-cy="trip-location"
            >
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-if="customFormFields">
          <custom-form-field
            v-for="(cff, i) in customFormFields"
            :ref="cff.id"
            :key="i"
            :cff="cff"
            :value="tripRequest.customFormFields[cff.id]"
            :readonly="readonly"
            @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
          ></custom-form-field>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CustomFormField from './CustomFormField.vue';
import { TRIP_STATUS } from '@/shared/common';

export default {
  name: 'StepGeneral',
  inject: ['eventHub'],
  components: { CustomFormField },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    customFormFields: Array,
    readonly: Boolean,
    step: Number,
  },
  data() {
    return {
      locations: [],
      filteredTripEvents: [],
      filteredTripTypes: [],
      TRIP_STATUS,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('app', ['clientConfig']),
    ...mapGetters('location', ['schools', 'locationsById']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesById']),
    ...mapGetters('tripEvent', ['tripEvents', 'tripEventsById']),
    ...mapGetters('tripRequest', ['isFieldReadonlyAfterApproval', 'isTripApprovedAndSubmittedByRequester']),
    categories() {
      let cat = [{ id: 1, name: 'Travel with Students' }];
      if (this.tripRequestConfig.display.staffCategory) cat = cat.concat({ id: 2, name: 'Staff Only' });
      return cat;
    },
    generalMessage() {
      if (this.tripRequestConfig.messages.general) return this.md(this.tripRequestConfig.messages.general);
      else return '';
    },
    usesRoster() {
      const isEliteTier = this.clientConfig.data.modules.tier === 'elite';
      const usesRoster = this.clientConfig.data.modules.roster.sync;
      return isEliteTier && usesRoster;
    },
  },
  created() {
    this.eventHub.$on('validateStepGeneral', () => this.validate());
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepGeneral');
  },
  methods: {
    ...mapMutations('tripRequest', ['setCurrentTripLocation']),
    async fetchItems() {
      this.locations = this.schools;
      this.locations.sort((a, b) => {
        return a[this.tripRequestConfig.other.locationSort].localeCompare(
          b[this.tripRequestConfig.other.locationSort],
          undefined,
          { numeric: this.tripRequestConfig.other.locationSort == 'code' }
        );
      });
      if (this.tripRequest.category) this.handleTripCategorySelect();
    },
    handleTripCategorySelect() {
      this.filteredTripTypes = this.tripTypes.filter(
        (e) => e.categoryId == this.tripRequest.category && e.availableOnForm
      );
      if (!this.filteredTripTypes.map((e) => e.id).includes(this.tripRequest.tripTypeId))
        this.tripRequest.tripTypeId = null;
      this.handleTripTypeSelect();
    },
    handleTripTypeSelect() {
      this.filteredTripEvents = this.tripEvents.filter((e) => e.tripTypeId == this.tripRequest.tripTypeId);
      if (
        this.tripRequest.tripEventIds.length &&
        !this.filteredTripEvents.map((e) => e.id).includes(this.tripRequest.tripEventIds[0])
      )
        this.tripRequest.tripEventIds = [];

      if (!this.tripRequest.tripEventIds.length) {
        const def = this.filteredTripEvents.find((e) => e.defaultEvent);
        if (def) this.tripRequest.tripEventIds = [def.id];
      }

      this.$emit('tripTypeSelected', this.tripRequest.tripTypeId);
      if (this.$refs['tripTypeId'] && this.$refs['tripEventIds']) {
        this.$refs['tripTypeId'].resetValidation();
        this.$refs['tripEventIds'].resetValidation();
      }
    },
    handleTripEventSelect(tripEvents) {
      if (!Array.isArray(tripEvents)) this.tripRequest.tripEventIds = [tripEvents];
    },
    limiter(e) {
      if (this.tripRequest.tripTypeId && this.tripTypesById[this.tripRequest.tripTypeId].allowEventRates) {
        if (e.length > 1) this.tripRequest.tripEventIds = [e[e.length - 1]];
        this.$refs.tripEventIds.blur();
      }
    },
    handleLocationSelect() {
      this.setCurrentTripLocation(this.locationsById[this.tripRequest.locationId]);
      if (this.tripRequest.stops && this.tripRequest.stops.length)
        this.eventHub.$emit('updateLocationInRouteRequested');
    },
    getLocationText(location) {
      return location.name + ' (' + location.code + ')';
    },
    validate() {
      const required = ['category', 'tripTypeId', 'tripEventIds', 'locationId'];
      required.forEach((e) => this.$refs[e].validate(true));
      if (this.customFormFields)
        this.customFormFields.forEach((e) => {
          if (e.required) this.$refs[e.id][0].cffValidation();
        });
    },
  },
  watch: {
    categories: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value.length == 1) this.tripRequest.category = 1;
      },
    },
  },
  mounted() {
    this.setCurrentTripLocation(this.locationsById[this.tripRequest.locationId]);
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
@media screen and (max-width: 768px) {
  [class^='col-xl-'],
  [class^='col-lg-'],
  [class^='col-md-'],
  [class^='col-sm-'],
  [class^='col-'] {
    padding: 0px;
  }
}
</style>
