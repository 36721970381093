var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      attrs: { text: "", color: "primary" },
      on: {
        click: function ($event) {
          return _vm.download()
        },
      },
    },
    [
      _c("v-icon", [_vm._v("mdi-download")]),
      _vm._v(" " + _vm._s(_vm.label) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }