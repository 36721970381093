var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-form",
            { staticClass: "full-width" },
            [
              _c(
                "v-row",
                { attrs: { align: "baseline" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              !_vm.tripRequest.status ||
                              _vm.tripRequest.status == -1
                                ? _c("span", { staticClass: "red--text" }, [
                                    _c("small", [_vm._v("*Required")]),
                                  ])
                                : _vm._e(),
                              _c("destination-autocomplete", {
                                ref: "destinationPicker",
                                attrs: {
                                  destinationFilters: _vm.tripRequestConfig
                                    .display.newDestination
                                    ? {}
                                    : { prospects: 0 },
                                  hint: _vm.tripRequestConfig.labels
                                    .commonDestinations,
                                  loading: _vm.loadingDestinations,
                                  includePlaces:
                                    _vm.tripRequestConfig.display
                                      .newDestination,
                                  readonly: _vm.isReadonly,
                                  clearable: !_vm.isReadonly,
                                  rules: [
                                    () =>
                                      !!_vm.tripRequest.destinationId ||
                                      "This field is required",
                                  ],
                                  semesterId: _vm.tripRequest.semesterId,
                                  label: "Main Destination",
                                  outlined: "",
                                  "persistent-hint": "",
                                  required: "",
                                  "data-cy": "trip-destination",
                                },
                                on: {
                                  change: _vm.handleDestination,
                                  destinationChanged: _vm.handleNewDestination,
                                  internalLoading: (val) =>
                                    (_vm.loadingDestinations = val),
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function ({ on, attrs, item }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {},
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            item.place_id
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "orange",
                                                      left: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-map-marker-plus"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "green",
                                                      left: "",
                                                    },
                                                  },
                                                  [_vm._v("mdi-map-marker")]
                                                ),
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item.text)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "append-item",
                                    fn: function () {
                                      return [
                                        _vm.tripRequestConfig.display
                                          .newDestination
                                          ? _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.createNewDestination()
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    "Having trouble finding an address? Click here create a new destination"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.tripRequest.destinationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripRequest,
                                      "destinationId",
                                      $$v
                                    )
                                  },
                                  expression: "tripRequest.destinationId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showStopButtons,
                                      expression: "showStopButtons",
                                    },
                                  ],
                                  attrs: {
                                    disabled: _vm.isReadonly,
                                    color: "primary",
                                    width: "100%",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addStopBack("location")
                                    },
                                  },
                                },
                                [_vm._v(" Add Location Stop ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showStopButtons,
                                      expression: "showStopButtons",
                                    },
                                  ],
                                  attrs: {
                                    disabled: _vm.isReadonly,
                                    color: "primary",
                                    width: "100%",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addStopBack("destination")
                                    },
                                  },
                                },
                                [_vm._v(" Add Destination Stop ")]
                              ),
                            ],
                            1
                          ),
                          _vm.tripRequestConfig.display.additionalStops
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showStopButtons,
                                          expression: "showStopButtons",
                                        },
                                      ],
                                      attrs: {
                                        disabled: _vm.isReadonly,
                                        color: "primary",
                                        width: "100%",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addStop()
                                        },
                                      },
                                    },
                                    [_vm._v(" Add Trip Stops ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("trip-itinerary", {
                    ref: "tripItinerary",
                    attrs: { tripRequest: _vm.tripRequest },
                    on: {
                      preventSubmit: function ($event) {
                        return _vm.$emit("preventSubmit", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.customFormFields
                ? _c(
                    "v-row",
                    _vm._l(_vm.customFormFields, function (cff, i) {
                      return _c("custom-form-field", {
                        key: i,
                        ref: cff.id,
                        refInFor: true,
                        attrs: {
                          cff: cff,
                          value: _vm.tripRequest.customFormFields[cff.id],
                          readonly:
                            (!Number.isInteger(_vm.step) &&
                              _vm.tripRequest.status == 1 &&
                              _vm.readonly &&
                              !_vm.tripRequest.permissions.canEditSiteAdmin) ||
                            _vm.cannotModifyFields,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.$emit("handleCFFInput", {
                              cffId: cff.id,
                              value: $event,
                            })
                          },
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("new-destination", {
        ref: "newDestination",
        attrs: { destination: _vm.destination, "prevent-autocomplete": "" },
        on: { newDestinationCreated: _vm.setNewDestinationId },
      }),
      _c("destination-stop", {
        ref: "stopeditor",
        attrs: { tripRequest: _vm.tripRequest },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }