<template>
  <v-col cols="12" md="6">
    <div class="text-h5 ml-18 d-inline-block">Build Trip Itinerary</div>

    <p class="ma-0 pa-0">
      <small class="blue-grey--text text--accent-3 font-weight-bold ml-18"
        >*You can click on a stop and drag to change order.</small
      >
    </p>

    <div v-if="tripRequest.distance > 0" class="text-subtitle-1 ml-18 d-inline-block">
      Total Mileage: {{ tripRequest.distance }} miles
    </div>

    <div v-if="tripRequest.time > 0" class="text-subtitle-1 ml-18 d-inline-block">Total Time: {{ time }}</div>

    <v-banner class="ma-2" v-if="!tripRequest.destinationId" rounded color="red lighten-4" icon="mdi-alert-box">
      You must have a main destination selected before editing your itinerary
    </v-banner>

    <v-list>
      <v-list-item-group color="primary" v-model="currentIndex">
        <draggable v-model="tripRequest.stops" @sort="handleSort" :disabled="fieldsDisabled">
          <v-list-item v-for="stop in existingStops" :key="stop.id">
            <v-list-item-icon>
              <v-icon :disabled="fieldsDisabled">mdi-drag-horizontal-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-icon class="mr-2">
              <v-icon>{{ getRouteIcon(stop) }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ stop.name }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn fab :disabled="fieldsDisabled" @click="delStop(stop)" small color="error" text
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list-item-group>
    </v-list>

    <v-row>
      <v-col v-if="tripRequest.destinationId">
        <div class="text-h6">Outbound Trip</div>

        <template v-if="loading">
          <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
          <div class="text-subtitle-1">Calculating route...</div>
        </template>

        <template v-else-if="successOutbound">
          <div class="text-subtitle-1"><strong>Miles to Destination:</strong> {{ oMileage }}</div>
          <div class="text-subtitle-1"><strong>Drive Time to Destination:</strong> {{ oTime }}</div>
          <v-btn color="primary" width="100%" @click="showDirections(0)">Outbound Directions</v-btn>
        </template>

        <template v-else>
          <v-banner
            :key="index"
            class="mb-2"
            color="light-blue lighten-4"
            rounded
            icon="mdi-alert"
            v-for="(message, index) in outboundErrors"
            >{{ message }}</v-banner
          >
        </template>
      </v-col>

      <v-col v-else></v-col>

      <div class="mx-6"></div>

      <v-col v-if="tripRequest.destinationId && hasReturnLeg">
        <div class="text-h6">Return Trip</div>

        <template v-if="loading">
          <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
          <div class="text-subtitle-1">Calculating route...</div>
        </template>

        <template v-else-if="successReturn">
          <div class="text-subtitle-1"><strong>Miles to Return:</strong> {{ rMileage }}</div>
          <div class="text-subtitle-1"><strong>Drive Time to Return:</strong> {{ rTime }}</div>
          <v-btn color="primary" width="100%" @click="showDirections(1)">Return Directions</v-btn>
        </template>

        <template v-else>
          <v-banner
            :key="index"
            class="mb-2"
            color="light-blue lighten-4"
            rounded
            icon="mdi-alert"
            v-for="(message, index) in returnErrors"
            >{{ message }}</v-banner
          >
        </template>
      </v-col>

      <v-col v-else></v-col>
    </v-row>

    <directions
      :destinationId="tripRequest.destinationId"
      :originId="tripRequest.locationId"
      :waypoints="waypoints"
      ref="directions"
    ></directions>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import { getMiles, getTime } from '@/util';
import { ROUTE } from '@/store/modules/Geo/actions';
import { DELETE_TRIP_REQUEST_STOP, SAVE_TRIP_REQUEST_STOP } from '@/store/modules/TripRequest/actions';

import Directions from '@/components/Directions.vue';

export default {
  name: 'TripItinerary',
  inject: ['eventHub'],
  components: { draggable, Directions },
  props: {
    tripRequest: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentIndex: null,
      loading: false,
      oDirections: [],
      oMileage: 0,
      oStops: [],
      oTime: '',
      rDirections: [],
      rMileage: 0,
      rStops: [],
      rTime: '',
      outboundErrors: [],
      returnErrors: [],
      successOutbound: false,
      successReturn: false,
      waypoints: (this.tripRequest.stops || [])
        .filter((stop) => stop.seq !== 0 && stop.seq !== this.tripRequest.stops.length - 1)
        .map((stop) => {
          let waypoint = `Waypoint ${stop.seq}`;
          return { [waypoint]: stop.name };
        }),
    };
  },
  created() {
    this.eventHub.$on('recalculateRoutes', () => this.calculateRoutes());
    this.eventHub.$on('updateLocationInRouteRequested', () => this.replaceLocationInStops());
  },
  beforeDestroy() {
    this.eventHub.$off('recalculateRoutes');
    this.eventHub.$off('updateLocationInRouteRequested');
  },
  computed: {
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('stop', ['stops']),
    destIndex() {
      const stops = this.existingStops.filter((s) => !s.delete);

      if (!stops.length) return -1;
      return stops.findIndex((e) => e.isDestination);
    },
    existingStops() {
      return (this.tripRequest.stops || []).filter((stop) => !stop?.delete);
    },
    fieldsDisabled() {
      return this.loading || !this.tripRequest.destinationId || !this.tripRequest.permissions.canEdit || this.readonly;
    },
    hasReturnLeg() {
      const stops = this.existingStops;
      const destinationIndex = this.destIndex;

      if (destinationIndex === -1) return false;
      return destinationIndex < stops.length - 1;
    },
    time() {
      return this.tripRequest.time ? getTime(this.tripRequest.time) : null;
    },
  },
  methods: {
    ...mapActions('geo', [ROUTE]),
    ...mapActions('tripRequest', [SAVE_TRIP_REQUEST_STOP, DELETE_TRIP_REQUEST_STOP]),
    async calculateRoutes() {
      if (this.fieldsDisabled) return;

      this.loading = true;
      this.eventHub.$emit('setNextButtonState', true);
      this.outboundErrors = [];
      this.returnErrors = [];

      const stops = this.tripRequest.stops.filter((s) => !s.delete);
      const destinationIndex = this.destIndex;

      // all points upto and including the destination
      this.oStops = stops.slice(0, destinationIndex + 1);
      // all points include and after the destination
      this.rStops = this.hasReturnLeg ? stops.slice(destinationIndex) : null;

      const oPoints = this.oStops.map((e) => e.address);
      const rPoints = this.rStops ? this.rStops.map((e) => e.address) : null;

      const oP = this[ROUTE]({ points: oPoints, options: { details: true } });
      const rP = rPoints
        ? this[ROUTE]({ points: rPoints, options: { details: true } })
        : Promise.resolve({ distance: 0, time: 0 });

      oP.then((response) => {
        this.successOutbound = true;
        this.oDirections = response.instructions;
        this.oMileage = getMiles(response.distance);
        this.oTime = getTime(response.time);

        return response;
      }).catch((error) =>
        this.handleGeocodeError(error, { successKey: 'successOutbound', errorKey: 'outboundErrors' })
      );

      rP.then((response) => {
        this.successReturn = true;
        this.rDirections = response ? response.instructions : null;
        this.rMileage = response ? getMiles(response.distance) : null;
        this.rTime = response ? getTime(response.time) : null;

        return response;
      }).catch((error) => this.handleGeocodeError(error, { successKey: 'successReturn', errorKey: 'returnErrors' }));

      try {
        const [o, r] = await Promise.all([oP, rP]);

        this.tripRequest.distance = r ? getMiles(o.distance + r.distance) : getMiles(o.distance);
        this.tripRequest.time = r ? o.time + r.time : o.time;
      } catch (error) {
        this.tripRequest.distance = 0;
        this.tripRequest.time = 0;
      } finally {
        this.$emit('preventSubmit', true);
        this.loading = false;

        await new Promise((resolve) => {
          this.eventHub.$emit('saveTripRequest', false, false, true, () => {
            resolve();
          });
        }).finally(() => {
          this.$emit('preventSubmit', false);
          this.eventHub.$emit('setNextButtonState', false);
        });
      }
    },
    async delStop(stop) {
      if (this.fieldsDisabled) return;

      const ok = await this.$myconfirm('Are you sure you want to remove this stop?');

      if (!ok) return;

      try {
        await this[DELETE_TRIP_REQUEST_STOP](stop.id);
        await this.refresh();

        this.$myalert.success('Stop removed');
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    getRouteIcon(s) {
      if (s && s.isLocation) return 'mdi-school';
      else if (s && s.isDestination) return 'mdi-map-marker-check';
      else return 'mdi-map-marker';
    },
    handleGeocodeError(error, { successKey, errorKey }) {
      const errors = this[errorKey];

      this[successKey] = false;

      if (error.errcode === '676A71B6') {
        errors.push(
          'One or more of the provided stops are missing coordinates. Directions and trip itinerary estimations could not be calculated'
        );
      }

      if (error.errcode === '676A71B7') {
        errors.push('Estimations and directions failed to calculate');
      }

      return error;
    },
    async handleSort({ oldIndex, newIndex }) {
      if (this.fieldsDisabled || oldIndex === newIndex) return;

      let index = 0;

      for (let stop of this.tripRequest.stops) {
        stop.seq = index;
        stop.simple = true;
        await this[SAVE_TRIP_REQUEST_STOP](stop);
        index++;
      }

      await this.calculateRoutes();

      this.$myalert.success('Stops reordered');
    },
    async refresh() {
      this.$emit('preventSubmit', true);

      await new Promise((resolve) => {
        this.eventHub.$emit('refreshTripRequest', this.tripRequest.id, () => {
          this.$nextTick(() => {
            this.calculateRoutes().then(resolve);
          });
        });
      }).finally(() => {
        this.$emit('preventSubmit', false);
      });
    },
    replaceLocationInStops() {
      const locationStopIndexes = [];

      for (let i = 0; i < this.tripRequest.stops.length; i++) {
        if (!this.tripRequest.stops[i].isLocation) continue;
        locationStopIndexes.push(i);
      }

      const locStop = this.stops.find((e) => e.schoolId == this.locationsById[this.tripRequest.locationId].schoolId);
      const tripStops = [...this.tripRequest.stops];

      for (let i of locationStopIndexes) {
        const temp = { ...tripStops[i], delete: true };

        tripStops[i] = {
          id: -1 * i,
          tripRequestId: this.tripRequest.id,
          stopId: locStop.id,
          name: locStop.name,
          address: locStop.address,
          seq: tripStops[i].seq,
          isLocation: true,
          simple: true,
        };

        tripStops.push(temp);
      }

      this.tripRequest.stops = tripStops;

      this.$nextTick(() => {
        this.calculateRoutes();
      });
    },
    showDirections(dir) {
      this.setWaypoints(dir);

      this.$refs.directions.dir = dir ? 'Return' : 'Outbound';
      this.$refs.directions.directions = dir ? this.rDirections : this.oDirections;
      this.$refs.directions.distance = dir ? this.rMileage : this.oMileage;
      this.$refs.directions.dialog = true;
    },
    setWaypoints(dir) {
      if (!this.tripRequest.stops) return;

      const stops = dir == 0 ? this.oStops : this.rStops;

      this.waypoints = stops
        .filter((e) => !e.isLocation && !e.isDestination)
        .map((stop, i) => {
          let waypoint = `Waypoint ${i + 1}`;

          return { [waypoint]: stop.name };
        });
    },
  },
};
</script>

<style scoped>
.ml-18 {
  margin-left: 18px;
}
</style>
