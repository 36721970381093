<template>
  <v-container fluid>
    <v-form class="full-width">
      <div>
        <v-row dense v-if="showEduObj">
          <v-col cols="12" md="12">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
            <v-textarea
              ref="educationalObjective"
              v-model="tripRequest.educationalObjective"
              label="Educational Objective of Trip"
              dense
              outlined
              rows="5"
              :rules="[() => !!tripRequest.educationalObjective || 'This field is required']"
              required
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense v-if="showSIs">
          <v-col cols="12" md="12" class="py-0">
            <h3 class="inline-block">Special Indicators:</h3>
            <v-subheader v-if="tripRequestConfig.display.specialIndicatorsRequired" class="inline red--text">
              (Choose at least 1)
            </v-subheader>
          </v-col>
          <template v-if="!specialIndicators || specialIndicators.length === 0">
            <v-col cols="12" md="12" class="py-0">
              <v-subheader>No Special Indicators found</v-subheader>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" md="3" v-for="(si, index) of specialIndicators" :key="index">
              <v-checkbox
                :value="si.id"
                v-model="tripRequest.specialIndicators"
                :label="si.name"
                multiple
                :readonly="readonly && isFieldReadonlyAfterApproval && !isTripApprovedAndSubmittedByRequester"
              ></v-checkbox>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" v-if="tripRequestConfig.display.substitute">
            <span v-if="tripRequestConfig.display.substituteRequired" class="red--text"><small>*Required</small></span>
            <v-radio-group
              v-model.number="tripRequest.needSubstitute"
              :hint="tripRequestConfig.display.substituteRequired ? 'Required*' : ''"
              :persistent-hint="tripRequestConfig.display.substituteRequired"
              :readonly="readonly"
            >
              <template v-slot:label>
                <div>Do you need a substitute?</div>
              </template>
              <v-radio :value="1">
                <template v-slot:label>
                  <div><strong class="success--text">Yes</strong></div>
                </template>
              </v-radio>
              <v-radio :value="0">
                <template v-slot:label>
                  <div><strong class="primary--text">No</strong></div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-if="tripRequestConfig.display.permissionSlip.includes(tripRequest.tripTypeId)">
          <v-col cols="12" md="12">
            <v-textarea
              v-model="tripRequest.instructionsForPermissionSlip"
              label="Special Instructions for Permission Slip"
              dense
              outlined
              rows="3"
              :readonly="readonly"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="customFormFields">
          <custom-form-field
            v-for="(cff, i) in customFormFields"
            :ref="cff.id"
            :key="i"
            :cff="cff"
            :value="tripRequest.customFormFields[cff.id]"
            :readonly="readonly && !isTripApprovedAndSubmittedByRequester"
            @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
          ></custom-form-field>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_SPECIAL_INDICATORS } from '@/store/modules/Config/actions';
import CustomFormField from './CustomFormField.vue';

export default {
  name: 'AdditionalInfo',
  inject: ['eventHub'],
  components: { CustomFormField },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    customFormFields: Array,
    readonly: Boolean,
    step: Number,
    complete: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['specialIndicators']),
    ...mapGetters('tripRequest', ['isFieldReadonlyAfterApproval', 'isTripApprovedAndSubmittedByRequester']),
    showSIs() {
      if (this.tripRequestConfig && this.me)
        return (
          this.tripRequestConfig.display.specialIndicators.includes(this.tripRequest.tripTypeId) &&
          (this.tripRequestConfig.display.specialIndicatorsAdmin
            ? this.me.is.superAdmin || this.me.is.transportationAdmin
            : true)
        );
      else return false;
    },
    showEduObj() {
      if (this.tripRequestConfig && this.tripRequestConfig.display.educationalObjective)
        return this.tripRequestConfig.display.educationalObjective.includes(this.tripRequest.tripTypeId);
      else return false;
    },
  },
  created() {
    this.eventHub.$on('validateStepAdditional', (reset) => this.validate(reset));
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepAdditional');
  },
  methods: {
    ...mapActions('config', [GET_SPECIAL_INDICATORS]),
    async fetchItems() {},
    validate(reset) {
      if (!reset) {
        if (this.showEduObj) this.$refs['educationalObjective'].validate(true);
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation();
          });
      } else {
        if (this.showEduObj) this.$refs['educationalObjective'].resetValidation();
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation(true);
          });
      }
    },
  },
  watch: {
    step(value) {
      if (value == 7 && this.tripRequest.status == 0 && !this.complete) this.tripRequest.needSubstitute = null;
    },
    complete(value) {
      if (this.step == 7 && this.tripRequest.status == 0 && !value) this.tripRequest.needSubstitute = null;
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
</style>
