var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    ref: "phoneNumber",
    attrs: {
      value: _vm.phone,
      label: _vm.label || "Phone Number",
      rules: _vm.combinedRules,
      maxlength: "12",
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      outlined: _vm.outlined,
    },
    on: { keydown: _vm.handleKeyDown, input: _vm.handleInput },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v
      },
      expression: "phone",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }