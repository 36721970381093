var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-form", { staticClass: "full-width" }, [
        _c(
          "div",
          [
            _vm.showEduObj
              ? _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        !_vm.tripRequest.status || _vm.tripRequest.status == -1
                          ? _c("span", { staticClass: "red--text" }, [
                              _c("small", [_vm._v("*Required")]),
                            ])
                          : _vm._e(),
                        _c("v-textarea", {
                          ref: "educationalObjective",
                          attrs: {
                            label: "Educational Objective of Trip",
                            dense: "",
                            outlined: "",
                            rows: "5",
                            rules: [
                              () =>
                                !!_vm.tripRequest.educationalObjective ||
                                "This field is required",
                            ],
                            required: "",
                            readonly: _vm.readonly,
                          },
                          model: {
                            value: _vm.tripRequest.educationalObjective,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tripRequest,
                                "educationalObjective",
                                $$v
                              )
                            },
                            expression: "tripRequest.educationalObjective",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSIs
              ? _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "12", md: "12" } },
                      [
                        _c("h3", { staticClass: "inline-block" }, [
                          _vm._v("Special Indicators:"),
                        ]),
                        _vm.tripRequestConfig.display.specialIndicatorsRequired
                          ? _c(
                              "v-subheader",
                              { staticClass: "inline red--text" },
                              [_vm._v(" (Choose at least 1) ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.specialIndicators || _vm.specialIndicators.length === 0
                      ? [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("v-subheader", [
                                _vm._v("No Special Indicators found"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      : _vm._l(_vm.specialIndicators, function (si, index) {
                          return _c(
                            "v-col",
                            { key: index, attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: si.id,
                                  label: si.name,
                                  multiple: "",
                                  readonly:
                                    _vm.readonly &&
                                    _vm.isFieldReadonlyAfterApproval &&
                                    !_vm.isTripApprovedAndSubmittedByRequester,
                                },
                                model: {
                                  value: _vm.tripRequest.specialIndicators,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.tripRequest,
                                      "specialIndicators",
                                      $$v
                                    )
                                  },
                                  expression: "tripRequest.specialIndicators",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "v-row",
              [
                _vm.tripRequestConfig.display.substitute
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _vm.tripRequestConfig.display.substituteRequired
                          ? _c("span", { staticClass: "red--text" }, [
                              _c("small", [_vm._v("*Required")]),
                            ])
                          : _vm._e(),
                        _c(
                          "v-radio-group",
                          {
                            attrs: {
                              hint: _vm.tripRequestConfig.display
                                .substituteRequired
                                ? "Required*"
                                : "",
                              "persistent-hint":
                                _vm.tripRequestConfig.display
                                  .substituteRequired,
                              readonly: _vm.readonly,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _vm._v("Do you need a substitute?"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1381134193
                            ),
                            model: {
                              value: _vm.tripRequest.needSubstitute,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tripRequest,
                                  "needSubstitute",
                                  _vm._n($$v)
                                )
                              },
                              expression: "tripRequest.needSubstitute",
                            },
                          },
                          [
                            _c("v-radio", {
                              attrs: { value: 1 },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _c(
                                            "strong",
                                            { staticClass: "success--text" },
                                            [_vm._v("Yes")]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3793165732
                              ),
                            }),
                            _c("v-radio", {
                              attrs: { value: 0 },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("div", [
                                          _c(
                                            "strong",
                                            { staticClass: "primary--text" },
                                            [_vm._v("No")]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2475461701
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-spacer"),
              ],
              1
            ),
            _vm.tripRequestConfig.display.permissionSlip.includes(
              _vm.tripRequest.tripTypeId
            )
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            label: "Special Instructions for Permission Slip",
                            dense: "",
                            outlined: "",
                            rows: "3",
                            readonly: _vm.readonly,
                          },
                          model: {
                            value:
                              _vm.tripRequest.instructionsForPermissionSlip,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tripRequest,
                                "instructionsForPermissionSlip",
                                $$v
                              )
                            },
                            expression:
                              "tripRequest.instructionsForPermissionSlip",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.customFormFields
              ? _c(
                  "v-row",
                  _vm._l(_vm.customFormFields, function (cff, i) {
                    return _c("custom-form-field", {
                      key: i,
                      ref: cff.id,
                      refInFor: true,
                      attrs: {
                        cff: cff,
                        value: _vm.tripRequest.customFormFields[cff.id],
                        readonly:
                          _vm.readonly &&
                          !_vm.isTripApprovedAndSubmittedByRequester,
                      },
                      on: {
                        handleCFFInput: function ($event) {
                          return _vm.$emit("handleCFFInput", {
                            cffId: cff.id,
                            value: $event,
                          })
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }