var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  "fixed-header": "",
                  headers: _vm.headers,
                  items: _vm.audit,
                  "item-key": "id",
                  "sort-by": "time",
                  "disable-sort": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.time`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  new Date(item.timestamp * 1000),
                                  "PP p"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }