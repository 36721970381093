<template>
  <v-container fluid>
    <v-form class="full-width" ref="transportationForm" v-model="isFormValid">
      <v-row v-if="tripRequestConfig.display.extTransportation">
        <v-col cols="12" md="12">
          <v-alert outlined type="info" text>
            <div>At least one option should be selected as "Yes".</div>
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense v-if="tripRequestConfig.display.extTransportation" class="align-baseline">
        <v-col cols="12" md="6">
          <v-label>{{ tripRequestConfig.labels.extTransportation }}</v-label>
        </v-col>

        <v-col cols="12" md="2">
          <div class="d-flex align-center justify-center">
            <span>No</span>
            <v-switch
              class="ml-4"
              v-model.number="tripRequest.needExternalTransportation"
              :true-value="1"
              :false-value="0"
              inset
              :readonly="isReadonly || isTripApprovedAndSubmittedByRequester"
            ></v-switch>
            <span>Yes</span>
          </div>
        </v-col>

        <v-col cols="12" md="12" v-show="tripRequest.needExternalTransportation">
          <v-textarea
            v-model="tripRequest.extTransportationDetail"
            :placeholder="tripRequestConfig.labels.extTransportationMode"
            dense
            persistent-placeholder
            outlined
            rows="3"
            :readonly="isReadonly || isTripApprovedAndSubmittedByRequester"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row dense v-if="tripRequestConfig.display.extTransportation" class="align-baseline">
        <v-col cols="12" md="6">
          <v-label>{{ tripRequestConfig.labels.vehiclesNeeded }}</v-label>
        </v-col>

        <v-col cols="12" md="2">
          <div class="d-flex align-center justify-center">
            <span>No</span>
            <v-switch
              class="ml-4"
              v-model.number="tripRequest.needDistrictVehicles"
              :true-value="1"
              :false-value="0"
              inset
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
              @change="onVehiclesNeededChange"
              data-cy="trip-vehicles-needed"
            ></v-switch
            ><span>Yes</span>
          </div>
        </v-col>
      </v-row>

      <v-row
        dense
        class="align-baseline"
        v-show="tripRequest.needDistrictVehicles && tripRequestConfig.display.driverAssigned"
      >
        <v-col cols="12" md="6">
          <v-label>{{ tripRequestConfig.labels.driverAssigned }}</v-label>
        </v-col>

        <v-col cols="12" md="2">
          <div class="d-flex align-center justify-center">
            <v-radio-group
              v-model="tripRequest.needDriverAssigned"
              ref="needDriverAssigned"
              row
              class="ml-4"
              :required="tripRequestConfig.display.requireDriverInfo"
              :readonly="isReadonly || isTripApprovedAndSubmittedByRequester"
              :rules="[
                () =>
                  !(tripRequestConfig.display.requireDriverInfo && tripRequest.needDriverAssigned == null) ||
                  'This field is required',
              ]"
            >
              <v-radio label="No" :value="0"></v-radio>
              <v-radio label="Yes" :value="1"></v-radio>
            </v-radio-group>
          </div>
        </v-col>
      </v-row>

      <v-row dense v-show="canEnterDriverName">
        <v-col cols="12" md="12">
          <v-textarea
            v-if="canEnterDriverName"
            :required="tripRequestConfig.display.requireDriverInfo"
            v-model="tripRequest.vehDriverInfo"
            :label="tripRequestConfig.labels.vehDriverInfo"
            dense
            outlined
            rows="3"
            :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            :rules="[
              (v) =>
                (tripRequestConfig.display.requireDriverInfo ? !tripRequest.vehDriverInfo.length == 0 : true) ||
                'Driver Info is required',
            ]"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3" v-show="tripRequest.needDistrictVehicles">
          <div class="required-div">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
          </div>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            ref="vehicleTypeId"
            v-model="tripRequest.vehicleTypeId"
            :items="vehicleTypeItems"
            item-text="name"
            item-value="id"
            label="Vehicle Type"
            outlined
            :rules="[() => !!tripRequest.vehicleTypeId || 'This field is required']"
            required
            :hint="noAvailableVehiclesHint"
            :persistent-hint="!!noAvailableVehiclesHint"
            @change="handleVehicleTypeSelected"
            :readonly="isReadonly || isTripApprovedAndSubmittedByRequester"
            data-cy="trip-vehicle-type"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
          v-if="
            tripRequest.needDistrictVehicles &&
            tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER &&
            (tripRequest.status != 0 || tripRequestConfig.display.contractors)
          "
        >
          <div class="required-div">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
          </div>

          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            ref="additionalTransportationId"
            v-model="tripRequest.additionalTransportationId"
            :items="charters"
            item-text="name"
            item-value="id"
            label="Approved Charter"
            outlined
            :rules="
              tripRequestConfig.display.requireContractors
                ? [() => !!tripRequest.additionalTransportationId || 'This field is required']
                : []
            "
            required
            :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
          >
          </v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
          v-if="
            tripRequest.needDistrictVehicles &&
            tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR &&
            (tripRequest.status != 0 || tripRequestConfig.display.contractors)
          "
        >
          <div class="required-div">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
          </div>

          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            ref="additionalTransportationId"
            v-model="tripRequest.additionalTransportationId"
            :items="contractors"
            item-text="name"
            item-value="id"
            label="Contractor"
            outlined
            :rules="
              tripRequestConfig.display.requireContractors
                ? [() => !!tripRequest.additionalTransportationId || 'This field is required']
                : []
            "
            required
            :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          md="3"
          v-show="
            tripRequest.needDistrictVehicles &&
            tripRequest.vehicleType == VEHICLE_TYPES.RENTAL_DEALERSHIP &&
            (tripRequest.status != 0 || tripRequestConfig.display.contractors)
          "
        >
          <div class="required-div"></div>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="tripRequest.additionalTransportationId"
            :items="rentals"
            item-text="name"
            item-value="id"
            label="Rental/Dealership Agency"
            outlined
            :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="2" v-show="tripRequest.needDistrictVehicles">
          <div class="required-div">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>
          </div>

          <v-text-field
            ref="numVehicles"
            v-model.number="tripRequest.numVehicles"
            label="Number of Vehicles Needed"
            type="number"
            min="0"
            :hint="tripRequestConfig.messages.studentsPerVehicle"
            :rules="[() => !!tripRequest.numVehicles || 'This field is required']"
            required
            :readonly="(readonly && isFieldReadonlyAfterApproval) || isTripApprovedAndSubmittedByRequester"
            data-cy="trip-num-vehicles"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
          v-show="
            tripRequest.needDistrictVehicles &&
            (tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER ||
              tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR) &&
            (tripRequest.status != 0 || tripRequestConfig.display.contractors)
          "
        >
          <v-row dense>
            <v-col cols="3">
              <div class="text-subtitle-2 font-weight-bold">Email:</div>
              <div class="text-subtitle-2 font-weight-bold">Phone:</div>
              <div class="text-subtitle-2 font-weight-bold">Contact:</div>
              <div class="text-subtitle-2 font-weight-bold">Address:</div>
            </v-col>

            <v-col cols="9">
              <div class="text-subtitle-2 font-weight-normal">{{ selectedAddTrans.email }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ selectedAddTrans.phone }}</div>
              <div class="text-subtitle-2 font-weight-normal">{{ selectedAddTrans.contactName }}</div>
              <div class="text-subtitle-2 font-weight-normal" v-html="selectedAddTrans.addressText"></div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="3" v-show="tripRequest.needDistrictVehicles && tripRequest.vehicleTypeId == -1">
          <v-row dense>
            <v-col cols="4">
              <div class="text-subtitle-2 font-weight-bold">Email:</div>
              <div class="text-subtitle-2 font-weight-bold">Address:</div>
            </v-col>

            <v-col cols="8">
              <div class="text-subtitle-2 font-weight-normal">{{ selectedAddTrans.email }}</div>
              <div class="text-subtitle-2 font-weight-normal" v-html="selectedAddTrans.addressText"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div v-show="tripRequest.needDistrictVehicles">
        <v-row dense>
          <v-col cols="12" md="3" v-if="showSplitTripOption">
            <v-tooltip top :disabled="!disableSplitTripOption">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-checkbox
                    :disabled="!!disableSplitTripOption"
                    label="Split Trip (2 pickup & return dates/times)"
                    v-bind="attrs"
                    v-model="tripRequest.splitTrip"
                    v-on="on"
                  ></v-checkbox>
                </div>
              </template>
              <span>Trips with created Assignments cannot be split</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" md="3" v-if="tripRequestConfig.display.dropoffOnly && !tripRequest.splitTrip">
            <v-checkbox
              v-model="tripRequest.dropoffOnly"
              label="Dropoff Only"
              @change="handleDOPU('do')"
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="3" v-if="tripRequestConfig.display.pickupOnly && !tripRequest.splitTrip">
            <v-checkbox
              v-model="tripRequest.pickupOnly"
              label="Pickup Only"
              @change="handleDOPU('pu')"
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" v-if="(tripRequest.dropoffOnly || tripRequest.pickupOnly) && !tripRequest.splitTrip">
            <v-text-field
              v-model="tripRequest.vehLocation"
              label="Location"
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense v-if="tripRequest.splitTrip">
          <v-col cols="6">
            <v-card outlined>
              <v-card-title>Drop-Off Leg</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <v-card outlined>
                      <v-card-title>Vehicle Pickup</v-card-title>
                      <v-card-text>
                        <v-col :cols="12">
                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>
                          <date-picker
                            ref="dropOffLegVehPickupDate"
                            label="Date"
                            v-model="tripRequest.dropOffLegVehPickupDate"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.dropOffLegVehPickupDate || 'This field is required']"
                            required
                          ></date-picker>
                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>
                          <time-picker
                            ref="dropOffLegVehPickupTime"
                            label="Time"
                            v-model="tripRequest.dropOffLegVehPickupTime"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.dropOffLegVehPickupTime || 'This field is required']"
                            required
                          ></time-picker>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card outlined>
                      <v-card-title>Vehicle Return</v-card-title>

                      <v-card-text>
                        <v-col :cols="12">
                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>

                          <date-picker
                            ref="dropOffLegVehReturnDate"
                            label="Date"
                            v-model="tripRequest.dropOffLegVehReturnDate"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.dropOffLegVehReturnDate || 'This field is required']"
                            required
                          ></date-picker>

                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>

                          <time-picker
                            ref="dropOffLegVehReturnTime"
                            label="Time"
                            v-model="tripRequest.dropOffLegVehReturnTime"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.dropOffLegVehReturnTime || 'This field is required']"
                            required
                          ></time-picker>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card outlined>
              <v-card-title>Return Leg</v-card-title>

              <v-card-text>
                <v-row dense>
                  <v-col cols="6">
                    <v-card outlined>
                      <v-card-title>Vehicle Pickup</v-card-title>

                      <v-card-text>
                        <v-col :cols="12">
                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>

                          <date-picker
                            ref="returnLegVehPickupDate"
                            label="Date"
                            v-model="tripRequest.returnLegVehPickupDate"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.returnLegVehPickupDate || 'This field is required']"
                            required
                          ></date-picker>

                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>

                          <time-picker
                            ref="returnLegVehPickupTime"
                            label="Time"
                            v-model="tripRequest.returnLegVehPickupTime"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.returnLegVehPickupTime || 'This field is required']"
                            required
                          ></time-picker>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col cols="6">
                    <v-card outlined>
                      <v-card-title>Vehicle Return</v-card-title>

                      <v-card-text>
                        <v-col :cols="12">
                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>

                          <date-picker
                            ref="returnLegVehReturnDate"
                            label="Date"
                            v-model="tripRequest.returnLegVehReturnDate"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.returnLegVehReturnDate || 'This field is required']"
                            required
                          ></date-picker>

                          <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                            <small>*Required</small>
                          </span>

                          <time-picker
                            ref="returnLegVehReturnTime"
                            label="Time"
                            v-model="tripRequest.returnLegVehReturnTime"
                            :readonly="
                              readonly &&
                              !tripRequest.permissions.canEditSiteAdmin &&
                              !canEditDates &&
                              !tripRequestConfig.display.nonAdminEditVehicleDateTime
                            "
                            :rules="[() => !!tripRequest.returnLegVehReturnTime || 'This field is required']"
                            required
                          ></time-picker>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 3 : 6" v-if="!tripRequest.splitTrip">
            <v-card outlined>
              <v-card-title>Vehicle Pick-up</v-card-title>

              <v-card-text>
                <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 12 : 6">
                  <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                    <small>*Required</small>
                  </span>

                  <date-picker
                    ref="vehPickupDate"
                    label="Date"
                    v-model="tripRequest.vehPickupDate"
                    :readonly="
                      readonly &&
                      !tripRequest.permissions.canEditSiteAdmin &&
                      !canEditDates &&
                      !tripRequestConfig.display.nonAdminEditVehicleDateTime
                    "
                    :rules="[() => !!tripRequest.vehPickupDate || 'This field is required']"
                    required
                  ></date-picker>

                  <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                    <small>*Required</small>
                  </span>

                  <time-picker
                    ref="vehPickupTime"
                    label="Time"
                    v-model="tripRequest.vehPickupTime"
                    :readonly="
                      readonly &&
                      !tripRequest.permissions.canEditSiteAdmin &&
                      !canEditDates &&
                      !tripRequestConfig.display.nonAdminEditVehicleDateTime
                    "
                    :rules="[() => !!tripRequest.vehPickupTime || 'This field is required']"
                    required
                  ></time-picker>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>

          <template v-if="showVenueDateTimes > 0">
            <v-spacer v-if="tripRequest.splitTrip"></v-spacer>

            <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 3 : 6" v-if="showBothCols || showDropoffCol">
              <v-card outlined>
                <v-card-title>Arrive at Venue (Info Only)</v-card-title>

                <v-card-text>
                  <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 12 : 6">
                    <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                      <small>*Required</small>
                    </span>

                    <date-picker
                      ref="venueArriveDate"
                      label="Date"
                      v-model="tripRequest.venueArriveDate"
                      :min="minArriveDepartDate"
                      :max="maxArriveDepartDate"
                      :readonly="
                        readonly &&
                        !tripRequest.permissions.canEditSiteAdmin &&
                        !canEditDates &&
                        !tripRequestConfig.display.nonAdminEditVehicleDateTime
                      "
                      :rules="[() => !!tripRequest.venueArriveDate || 'This field is required']"
                      required
                    ></date-picker>

                    <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                      <small>*Required</small>
                    </span>

                    <time-picker
                      ref="venueArriveTime"
                      label="Time"
                      v-model="tripRequest.venueArriveTime"
                      :readonly="
                        readonly &&
                        !tripRequest.permissions.canEditSiteAdmin &&
                        !canEditDates &&
                        !tripRequestConfig.display.nonAdminEditVehicleDateTime
                      "
                      :rules="[() => !!tripRequest.venueArriveTime || 'This field is required']"
                      required
                    ></time-picker>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 3 : 6" v-if="showBothCols || showPickupCol">
              <v-card outlined>
                <v-card-title>Depart Venue (Info Only)</v-card-title>

                <v-card-text>
                  <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 12 : 6">
                    <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                      <small>*Required</small>
                    </span>

                    <date-picker
                      ref="venueDepartDate"
                      label="Date"
                      v-model="tripRequest.venueDepartDate"
                      :min="minArriveDepartDate"
                      :max="maxArriveDepartDate"
                      :readonly="
                        readonly &&
                        !tripRequest.permissions.canEditSiteAdmin &&
                        !canEditDates &&
                        !tripRequestConfig.display.nonAdminEditVehicleDateTime
                      "
                      :rules="[() => !!tripRequest.venueDepartDate || 'This field is required']"
                      required
                    ></date-picker>

                    <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                      <small>*Required</small>
                    </span>

                    <time-picker
                      ref="venueDepartTime"
                      label="Time"
                      v-model="tripRequest.venueDepartTime"
                      :readonly="
                        readonly &&
                        !tripRequest.permissions.canEditSiteAdmin &&
                        !canEditDates &&
                        !tripRequestConfig.display.nonAdminEditVehicleDateTime
                      "
                      :rules="[() => !!tripRequest.venueDepartTime || 'This field is required']"
                      required
                    ></time-picker>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>

            <v-spacer v-if="tripRequest.splitTrip"></v-spacer>
          </template>

          <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 3 : 6" v-if="!tripRequest.splitTrip">
            <v-card outlined>
              <v-card-title>Vehicle Return</v-card-title>

              <v-card-text>
                <v-col :cols="isMobile ? 12 : showVenueDateTimes > 0 ? 12 : 6">
                  <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                    <small>*Required</small>
                  </span>

                  <date-picker
                    ref="vehReturnDate"
                    label="Date"
                    v-model="tripRequest.vehReturnDate"
                    :readonly="
                      readonly &&
                      !tripRequest.permissions.canEditSiteAdmin &&
                      !canEditDates &&
                      !tripRequestConfig.display.nonAdminEditVehicleDateTime
                    "
                    :rules="[() => !!tripRequest.vehReturnDate || 'This field is required']"
                    required
                  ></date-picker>

                  <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                    <small>*Required</small>
                  </span>

                  <time-picker
                    ref="vehReturnTime"
                    label="Time"
                    v-model="tripRequest.vehReturnTime"
                    :readonly="
                      readonly &&
                      !tripRequest.permissions.canEditSiteAdmin &&
                      !canEditDates &&
                      !tripRequestConfig.display.nonAdminEditVehicleDateTime
                    "
                    :rules="[() => !!tripRequest.vehReturnTime || 'This field is required']"
                    required
                  ></time-picker>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense v-if="tripRequest.splitTrip">
          <v-col cols="6">
            <div class="font-weight-medium">
              Drop-Off Leg Hours<span class="pl-2">{{ tripRequest.dropOffTotalHours || '0' }}</span>
            </div>
            <div class="font-weight-medium">
              Total Trip Hours<span class="pl-2">{{ tripRequest.totalTripHours || '0' }}</span>
            </div>
          </v-col>

          <v-col cols="6">
            <div class="font-weight-medium">
              Return Leg Hours<span class="pl-2">{{ tripRequest.returnTotalHours || '0' }}</span>
            </div></v-col
          >
        </v-row>

        <v-row v-for="am in activeMessages" :key="am.id" dense>
          <v-col cols="12" md="12">
            <v-alert outlined :type="am.alertType || 'info'" text>{{ am.message }}</v-alert>
          </v-col>
        </v-row>

        <v-row dense v-if="tripRequestConfig.display.specialNeedsVehicle">
          <v-col cols="12" md="4">
            <v-checkbox
              :label="tripRequestConfig.labels.needSpecialNeedsVehicles"
              :readonly="cannotModifyFields"
              v-model="tripRequest.needSpecialNeedsVehicle"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="2"
            v-if="tripRequestConfig.display.numSpecialNeedsVehicle && tripRequest.needSpecialNeedsVehicle"
          >
            <v-text-field
              :label="tripRequestConfig.labels.numSpecialNeedsVehicles"
              :readonly="cannotModifyFields"
              min="0"
              type="number"
              v-model.number="tripRequest.numSpecialNeedsVehicle"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2" v-if="tripRequestConfig.labels.numWheelchairSlots">
            <v-text-field
              :label="tripRequestConfig.labels.numWheelchairSlots"
              :readonly="cannotModifyFields"
              min="0"
              type="number"
              v-model.number="tripRequest.numWheelchairSlot"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2" v-if="tripRequestConfig.labels.numSafetyVests">
            <v-text-field
              :label="tripRequestConfig.labels.numSafetyVests"
              :readonly="cannotModifyFields"
              min="0"
              type="number"
              v-model.number="tripRequest.numSafetyVest"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2" v-if="tripRequestConfig.labels.numFoldDownSeats">
            <v-text-field
              :label="tripRequestConfig.labels.numFoldDownSeats"
              :readonly="cannotModifyFields"
              min="0"
              type="number"
              v-model.number="tripRequest.numFoldDownSeat"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="12" class="py-0">
            <h3>Special Travel Needs:</h3>
          </v-col>

          <v-col cols="12" md="3" v-for="(stn, index) of specialTravelNeeds" :key="index">
            <v-checkbox
              :value="stn.id"
              v-model="tripRequest.specialTravelNeeds"
              :label="stn.name"
              multiple
              :readonly="(readonly && isFieldReadonlyAfterApproval) || isTripApprovedAndSubmittedByRequester"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="12" v-if="tripRequestConfig.display.commentsConcerningVehicle">
            <v-textarea
              v-model="tripRequest.vehComments"
              :label="tripRequestConfig.labels.vehicleComments"
              dense
              outlined
              rows="3"
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <span v-if="tripRequestConfig.display.comments" class="red--text">
              <small>*Required</small>
            </span>

            <v-textarea
              :hint="tripRequestConfig.messages.comments"
              :readonly="readonly"
              :rules="[
                () => (tripRequestConfig.display.comments ? !!tripRequest.comments : true) || 'This field is required',
              ]"
              auto-grow
              counter="400"
              label="Comments"
              maxlength="400"
              outlined
              persistent-hint
              ref="comments"
              required
              rows="3"
              v-model="tripRequest.comments"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>

      <v-row v-if="customFormFields">
        <custom-form-field
          v-for="(cff, i) in customFormFields"
          :ref="cff.id"
          :key="i"
          :cff="cff"
          :value="tripRequest.customFormFields[cff.id]"
          :readonly="readonly"
          @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
        ></custom-form-field>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addDays, format } from 'date-fns';
import { GET_AVAILABLE_VEHICLES } from '@/store/modules/Vehicle/actions';
import CustomFormField from './CustomFormField.vue';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import { validateSplitTripDates } from '@/util/tripRequest';
import VEHICLE_TYPES from '@/shared/types';
import { TRIP_STATUS } from '@/shared/common';

export default {
  name: 'Transportation',
  inject: ['eventHub'],
  components: { DatePicker, TimePicker, CustomFormField },
  props: {
    customFormFields: Array,
    readonly: Boolean,
    step: Number,
    tripRequest: Object,
    tripRequestConfig: Object,
  },
  data() {
    return {
      rules: {
        email: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      },
      activeMessages: [],
      charters: [],
      contractors: [],
      isFormValid: false,
      noAvailableVehiclesHint: null,
      rentals: [],
      VEHICLE_TYPES,
      vehicleTypeItems: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('user', ['me']),
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('vehicleType', ['vehicleTypes', 'vehicleTypesById']),
    ...mapGetters('additionalTransportation', ['additionalTransportations']),
    ...mapGetters('config', ['specialTravelNeeds', 'driverConfig']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripRequest', ['isFieldReadonlyAfterApproval', 'isTripApprovedAndSubmittedByRequester']),
    cannotModifyFields() {
      return (
        (this.readonly && !this.tripRequest.permissions.canEditSiteAdmin) ||
        ((!this.tripRequest.permissions.canEditPreApproval ||
          !this.tripRequest.permissions.canEditMidApproval ||
          !this.tripRequest.permissions.canEditApproved) &&
          !Number.isInteger(this.step) &&
          this.tripRequest.status !== TRIP_STATUS.DRAFT)
      );
    },
    canEnterDriverName() {
      if (this.tripRequest.needDriverAssigned === null) return false;
      if (this.tripRequest.needDriverAssigned) return false;
      if (!this.tripRequestConfig.display.driverAssigned) return false;

      return true;
    },
    selectedAddTrans() {
      if (this.tripRequest.additionalTransportationId && this.additionalTransportations.length) {
        const found = this.additionalTransportations.find((e) => e.id == this.tripRequest.additionalTransportationId);
        if (found.address)
          found.addressText = `${found.address.address} <br> ${found.address.city}, ${found.address.state} ${found.address.zip}`;
        return found;
      } else return {};
    },
    showVenueDateTimes() {
      if (this.tripRequestConfig && this.tripRequestConfig.display.venueDateTime.includes(this.tripRequest.tripTypeId))
        return true;
      else return false;
    },
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
    canEditDates() {
      if (!this.tripRequest.assignments) return true;
      return this.tripRequest.permissions.canEditApproved && !this.tripRequest.assignmentsComplete;
    },
    showSplitTripOption() {
      return (
        this.tripRequestConfig.display.splitTrips &&
        (this.tripRequest.approval.approved || !this.tripRequest.id) &&
        (this.isAdmin || this.me.is.vehicleOwner || this.me.is.specialNeedsVehicleOwner)
      );
    },
    disableSplitTripOption() {
      return !this.tripRequest.splitTrip && this.tripRequest.assignments && this.tripRequest.assignments.length;
    },
    showBothCols() {
      return !this.tripRequest.dropoffOnly && !this.tripRequest.pickupOnly;
    },
    showDropoffCol() {
      return this.tripRequest.dropoffOnly;
    },
    showPickupCol() {
      return this.tripRequest.pickupOnly;
    },
    minArriveDepartDate() {
      return [TRIP_STATUS.DENIED, TRIP_STATUS.RESUBMIT, TRIP_STATUS.DRAFT].indexOf(this.tripRequest.status) > -1
        ? format(new Date(this.tripRequest.leaveDate), 'yyyy-MM-dd')
        : undefined;
    },
    maxArriveDepartDate() {
      return [TRIP_STATUS.DENIED, TRIP_STATUS.RESUBMIT, TRIP_STATUS.DRAFT].indexOf(this.tripRequest.status) > -1
        ? format(addDays(new Date(this.tripRequest.returnDate), 1), 'yyyy-MM-dd')
        : undefined;
    },
    isReadonly() {
      return (
        this.readonly && !this.tripRequest.permissions.canEditSiteAdmin && !this.tripRequest.permissions.canEditApproved
      );
    },
  },
  created() {
    this.eventHub.$on('validateStepTransportation', (reset) => this.validate(reset));
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepTransportation');
  },
  methods: {
    ...mapActions('vehicle', [GET_AVAILABLE_VEHICLES]),
    async fetchItems() {
      if (this.tripRequest.tripTypeId) {
        this.charters = this.additionalTransportations.filter((e) => e.type == 'charter');
        this.contractors = this.additionalTransportations.filter((e) => e.type == 'contractor' && e.active);
        this.rentals = this.additionalTransportations.filter((e) => e.type == 'rental');
        const tripType = this.tripTypes.find((e) => e.id == this.tripRequest.tripTypeId);
        this.vehicleTypeItems =
          tripType.vehicleTypes && tripType.vehicleTypes.length
            ? [...this.vehicleTypes.filter((e) => tripType.vehicleTypes.includes(e.id))]
            : [];
        const charterIndex = this.vehicleTypeItems.findIndex(
          (e) => e.vehicleType == this.VEHICLE_TYPES.APPROVED_CHARTER
        );
        if (charterIndex >= 0 && !this.charters.length) this.vehicleTypeItems.splice(charterIndex, 1);
        const contractorIndex = this.vehicleTypeItems.findIndex((e) => e.vehicleType == this.VEHICLE_TYPES.CONTRACTOR);
        if (contractorIndex >= 0 && !this.contractors.length) this.vehicleTypeItems.splice(contractorIndex, 1);
        const rentalIndex = this.vehicleTypeItems.findIndex(
          (e) => e.vehicleType == this.VEHICLE_TYPES.RENTAL_DEALERSHIP
        );
        if (rentalIndex >= 0 && !this.rentals.length) this.vehicleTypeItems.splice(rentalIndex, 1);
        if (this.tripRequest.tripTypeId == 4) this.vehicleTypeItems = this.vehicleTypeItems.filter((e) => e.staffUse);
        this.vehicleTypeItems = this.vehicleTypeItems.filter((e) => e.active && (this.isAdmin || e.available));
      }
      if (this.tripRequest.vehicleTypeId) this.checkAutoAssign(this.tripRequest.vehicleTypeId);
    },
    async handleVehicleTypeSelected(vehicleTypeId) {
      this.tripRequest.additionalTransportationId = 0;
      this.tripRequest.vehicleType = this.vehicleTypeItems.find((vehicle) => vehicle.id === vehicleTypeId)?.vehicleType;
      await this.checkAutoAssign(vehicleTypeId);
    },
    async checkAutoAssign(vehicleTypeId) {
      if (
        this.tripRequest.vehicleType === VEHICLE_TYPES.NORMAL &&
        this.vehicleTypesById[vehicleTypeId].autoAssign &&
        this.vehicleTypesById[vehicleTypeId].autoAssignTripTypes &&
        this.vehicleTypesById[vehicleTypeId].autoAssignTripTypes.includes(this.tripRequest.tripTypeId)
      ) {
        const available = await this.getAvailableVehicles({
          fromDate: this.tripRequest.leaveDate,
          fromTime: this.tripRequest.leaveTime,
          toDate: this.tripRequest.returnDate,
          toTime: this.tripRequest.returnTime,
          type: vehicleTypeId,
        });
        if (!available.length) this.noAvailableVehiclesHint = this.tripRequestConfig.messages.noVehiclesAvailable;
        else this.noAvailableVehiclesHint = null;
      } else this.noAvailableVehiclesHint = null;
    },
    handleDOPU(which) {
      if (which === 'do') this.tripRequest.pickupOnly = false;
      else if (which === 'pu') this.tripRequest.dropoffOnly = false;
      this.validate(true);
    },
    checkTripDates() {
      let prevent = false;

      if (this.tripRequestConfig.display.venueDateTime.includes(this.tripRequest.tripTypeId)) {
        if (this.showDropoffCol) {
          prevent = this.validateDropoff();
        } else if (this.showPickupCol) {
          prevent = this.validatePickup();
        } else if (this.showBothCols) {
          prevent = this.validateDropoff() || this.validatePickup();
        }
        prevent = prevent || this.validateVenueDates();
      }

      this.$emit('preventSubmit', prevent);
    },

    validateDropoff() {
      if (
        this.isVenueArriveBeforeLeaveDate() ||
        this.isVenueArriveAfterReturnDate() ||
        this.isVenueArriveTimeInvalid()
      ) {
        return true;
      }
      return false;
    },

    validatePickup() {
      if (
        this.isVenueDepartBeforeLeaveDate() ||
        this.isVenueDepartAfterReturnDate() ||
        this.isVenueDepartTimeInvalid()
      ) {
        return true;
      }
      return false;
    },

    validateVenueDates() {
      if (this.isVenueArriveAfterDepartDate() || this.isVenueArriveTimeAfterDepartTime()) {
        return true;
      }
      return false;
    },

    isVenueArriveAfterReturnDate() {
      if (this.tripRequest.venueArriveDate && this.tripRequest.venueArriveDate > this.tripRequest.vehReturnDate) {
        this.activeMessages.push({
          message: 'Venue Arrive date cannot be after Trip Return date',
          alertType: 'error',
        });
        return true;
      }
      return false;
    },

    isVenueArriveBeforeLeaveDate() {
      if (this.tripRequest.venueArriveDate && this.tripRequest.venueArriveDate < this.tripRequest.vehPickupDate) {
        this.activeMessages.push({
          message: 'Venue Arrive date cannot be before Trip Start date',
          alertType: 'error',
        });
        return true;
      }
      return false;
    },

    isVenueArriveTimeInvalid() {
      if (this.tripRequest.venueArriveDate && this.tripRequest.venueArriveDate == this.tripRequest.vehPickupDate) {
        if (!this.tripRequest.venueArriveTime) {
          this.activeMessages.push({
            message: 'Please enter a Venue Arrive time',
            alertType: 'error',
          });
          return true;
        }

        if (
          this.tripRequest.venueArriveTime.localeCompare(this.tripRequest.vehPickupTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) < 0
        ) {
          this.activeMessages.push({
            message: 'Venue Arrive time cannot be before Trip Start time',
            alertType: 'error',
          });
          return true;
        }
      }
      return false;
    },

    isVenueDepartBeforeLeaveDate() {
      if (this.tripRequest.venueDepartDate && this.tripRequest.venueDepartDate < this.tripRequest.vehPickupDate) {
        this.activeMessages.push({
          message: 'Venue Depart date cannot be before Trip Start date',
          alertType: 'error',
        });
        return true;
      }
      return false;
    },

    isVenueDepartAfterReturnDate() {
      if (this.tripRequest.venueDepartDate && this.tripRequest.venueDepartDate > this.tripRequest.vehReturnDate) {
        this.activeMessages.push({
          message: 'Venue Depart date cannot be after Trip Return date',
          alertType: 'error',
        });
        return true;
      }
      return false;
    },

    isVenueDepartTimeInvalid() {
      if (this.tripRequest.venueDepartDate && this.tripRequest.venueDepartDate == this.tripRequest.vehReturnDate) {
        if (!this.tripRequest.venueDepartTime) {
          this.activeMessages.push({
            message: 'Please enter a Venue Depart time',
            alertType: 'error',
          });
          return true;
        }

        if (
          this.tripRequest.venueDepartTime.localeCompare(this.tripRequest.vehReturnTime, undefined, {
            ignorePunctuation: true,
            numeric: true,
          }) > 0
        ) {
          this.activeMessages.push({
            message: 'Venue Depart time cannot be after Trip Return time',
            alertType: 'error',
          });
          return true;
        }
      }
      return false;
    },

    isVenueArriveAfterDepartDate() {
      if (this.tripRequest.venueDepartDate && this.tripRequest.venueArriveDate) {
        if (this.tripRequest.venueArriveDate > this.tripRequest.venueDepartDate) {
          this.activeMessages.push({
            message: 'Venue Arrive date cannot be after Venue Depart date',
            alertType: 'error',
          });
          return true;
        }
      }
      return false;
    },

    isVenueArriveTimeAfterDepartTime() {
      if (
        this.tripRequest.venueDepartDate &&
        this.tripRequest.venueArriveDate &&
        this.tripRequest.venueArriveDate == this.tripRequest.venueDepartDate &&
        this.tripRequest.venueArriveTime &&
        this.tripRequest.venueDepartTime &&
        this.tripRequest.venueArriveTime.localeCompare(this.tripRequest.venueDepartTime, undefined, {
          ignorePunctuation: true,
          numeric: true,
        }) > 0
      ) {
        this.activeMessages.push({
          message: 'Venue Arrive time cannot be after Venue Depart time',
          alertType: 'error',
        });
        return true;
      }
      return false;
    },
    checkSplitTripDates() {
      const errorMessage = validateSplitTripDates(this.tripRequest);

      let prevent = false;
      if (errorMessage) {
        this.activeMessages.push({
          message: errorMessage,
          alertType: 'error',
        });
        prevent = true;
      }
      this.$emit('preventSubmit', prevent);
    },
    onVehiclesNeededChange(value) {
      if (this.tripRequestConfig.display.driverAssigned) {
        this.validate(true);
      }
    },
    validate(reset) {
      let required = [
        'vehicleTypeId',
        'numVehicles',
        'vehPickupDate',
        'vehPickupTime',
        'vehReturnDate',
        'vehReturnTime',
      ];

      if (typeof reset === 'undefined') {
        this.$refs.transportationForm.validate();
        if (!this.isFormValid) required.push('vehDriverInfo');
      }

      if (this.tripRequestConfig.display.comments) required.push('comments');
      if (this.showVenueDateTimes)
        required = required.concat(['venueArriveDate', 'venueArriveTime', 'venueDepartDate', 'venueDepartTime']);
      if (this.tripRequest.splitTrip)
        required = required.concat([
          'dropOffLegVehPickupDate',
          'dropOffLegVehPickupTime',
          'dropOffLegVehReturnDate',
          'dropOffLegVehReturnTime',
          'returnLegVehPickupDate',
          'returnLegVehPickupTime',
          'returnLegVehReturnDate',
          'returnLegVehReturnTime',
        ]);
      if (this.tripRequest.needDistrictVehicles && this.tripRequestConfig.display.driverAssigned)
        required = required.concat(['needDriverAssigned']);
      if (this.tripRequestConfig.display.requireContractors && this.$refs['additionalTransportationId'])
        required.push('additionalTransportationId');
      if (!reset) {
        required.forEach((e) => this.$refs[e].validate(true));
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation();
          });
      } else {
        required.forEach((e) => this.$refs[e].resetValidation());
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation(true);
          });
      }
    },
  },
  watch: {
    tripRequest: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value.tripTypeId) this.fetchItems();
        if (this.step !== 5) return;

        if (
          value.leaveDate &&
          value.leaveTime &&
          value.returnDate &&
          value.returnTime &&
          !value.vehPickupDate &&
          !value.vehPickupTime &&
          !value.vehReturnDate &&
          !value.vehReturnTime
        ) {
          this.tripRequest.vehPickupDate = value.leaveDate;
          this.tripRequest.vehPickupTime = value.leaveTime;
          this.tripRequest.vehReturnDate = value.returnDate;
          this.tripRequest.vehReturnTime = value.returnTime;
          this.tripRequest.venueArriveDate = value.leaveDate;
          this.tripRequest.venueDepartDate = value.returnDate;
        }
        this.activeMessages = [];
        if (value.splitTrip) {
          if (
            !value.dropOffLegVehPickupDate &&
            !value.dropOffLegVehPickupTime &&
            !value.dropOffLegVehReturnDate &&
            !value.dropOffLegVehReturnTime &&
            !value.returnLegVehPickupDate &&
            !value.returnLegVehPickupTime &&
            !value.returnLegVehReturnDate &&
            !value.returnLegVehReturnTime
          ) {
            this.tripRequest.dropOffLegVehPickupDate = value.leaveDate;
            this.tripRequest.dropOffLegVehPickupTime = value.leaveTime;
            this.tripRequest.dropOffLegVehReturnDate = value.leaveDate;
            this.tripRequest.dropOffLegVehReturnTime = '';
            this.tripRequest.returnLegVehPickupDate = value.returnDate;
            this.tripRequest.returnLegVehPickupTime = '';
            this.tripRequest.returnLegVehReturnDate = value.returnDate;
            this.tripRequest.returnLegVehReturnTime = value.returnTime;
          } else {
            const dropOffLegPickupDate = new Date(
              `${this.tripRequest.dropOffLegVehPickupDate} ${this.tripRequest.dropOffLegVehPickupTime}`
            );
            const dropOffLegReturnDate = new Date(
              `${this.tripRequest.dropOffLegVehReturnDate} ${this.tripRequest.dropOffLegVehReturnTime}`
            );
            const returnDateLegPickupDate = new Date(
              `${this.tripRequest.returnLegVehPickupDate} ${this.tripRequest.returnLegVehPickupTime}`
            );
            const returnLegReturnDate = new Date(
              `${this.tripRequest.returnLegVehReturnDate} ${this.tripRequest.returnLegVehReturnTime}`
            );
            this.tripRequest.dropOffTotalHours =
              Math.abs(dropOffLegReturnDate.getTime() - dropOffLegPickupDate.getTime()) / 3600000;
            this.tripRequest.returnTotalHours =
              Math.abs(returnLegReturnDate.getTime() - returnDateLegPickupDate.getTime()) / 3600000;
            this.tripRequest.totalTripHours = this.tripRequest.returnTotalHours + this.tripRequest.dropOffTotalHours;
          }
          this.checkSplitTripDates();
        } else this.checkTripDates();
        if (value.tripTypeId && !value.vehicleTypeId && this.tripTypesById[value.tripTypeId].defaultVehicleTypeId)
          this.tripRequest.vehicleTypeId = this.tripTypesById[value.tripTypeId].defaultVehicleTypeId;
      },
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.required-div {
  height: 25px;
}
</style>
