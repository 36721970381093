<template>
  <v-dialog v-model="dialog" max-width="600px" no-click-animation persistent>
    <v-card>
      <v-card-title>Decline Assignment</v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" @submit="decline">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="notes" outlined label="Notes"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> Back </v-btn>
        <v-btn color="primary" @click="decline()" :loading="saving" :disabled="saving || !notes">
          Decline Assignment
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { ACCEPT_ASSIGNMENT } from '@/store/modules/Assignment/actions';
import { ASSIGNMENT_STATUS } from '@/shared/common';

export default {
  name: 'DeclineAssignment',
  data() {
    return {
      dialog: false,
      assignmentId: 0,
      adminDecline: false,
      notes: '',
      saving: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions('assignment', [ACCEPT_ASSIGNMENT]),
    async decline() {
      this.saving = true;
      try {
        await this.acceptAssignment({
          assignmentId: this.assignmentId,
          status: this.adminDecline ? ASSIGNMENT_STATUS.ADMIN_DECLINED : ASSIGNMENT_STATUS.SELF_DECLINED,
          notes: this.notes,
        });
        this.$myalert.success('Assignment declined successfully');
        this.$emit('refresh');
      } catch (error) {
        this.$myalert.error(error.message);
      }
      this.close();
      this.saving = false;
    },
    close() {
      this.dialog = false;
      this.assignmentId = 0;
      this.adminDecline = false;
      this.notes = '';
    },
  },
};
</script>

<style lang="scss"></style>
