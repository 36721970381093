<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table fixed-header :headers="headers" :items="audit" item-key="id" sort-by="time" disable-sort>
          <template v-slot:[`item.time`]="{ item }">
            {{ format(new Date(item.timestamp * 1000), 'PP p') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { getAuditDescription } from '@/util';

export default {
  name: 'TripRequestAudit',
  inject: ['eventHub'],
  mixins: [],
  components: {},
  props: {
    auditHistory: Array,
    approval: Object,
  },
  data() {
    return {
      format,
      headers: [
        { text: 'Date', value: 'time' },
        { text: 'User', value: 'user' },
        { text: 'Action', value: 'action' },
        { text: 'Comment', value: 'comment' },
      ],
      approvalActions: {
        '-2': 'Denied',
        '-1': 'Changes Requested',
        0: 'Changes Requested',
        1: 'Approved',
      },
    };
  },
  computed: {
    ...mapGetters('approvalLevel', ['approvalLevelsById']),
    audit() {
      if (!this.auditHistory || !this.approval) return [];

      const auditHistoryMapped = this.auditHistory.map((e) => ({
        timestamp: e.timestamp,
        user: e.displayName || 'System',
        action: getAuditDescription(e.description),
      }));

      const approvalHistoryMapped = this.approval.history.reduce((acc, e) => {
        if (e.details?.approvedBy === e?.details?.userId) {
          const approvalLevelName = this.approvalLevelsById?.[e.approvalLevelId]?.name || 'Unknown level';
          const approvalAction = this.approvalActions?.[e.approved] || 'Unknown action';

          acc.push({
            timestamp: e.timestamp,
            user: e.user,
            action: `${approvalLevelName} - ${approvalAction}`,
            comment: e.comments,
          });
        }
        return acc;
      }, []);

      return [...auditHistoryMapped, ...approvalHistoryMapped].sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
